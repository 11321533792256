<template>

  <div>
    <LbrxLoaderSpinner :is-loaded="!loading" />
  </div>

  <div :class="{ 'is-rtl': textDirection==='rtl'}">
    <a-breadcrumb style="margin: 16px 0">
      <a-breadcrumb-item @click="navigatePage('/')" class="breadcrumb-icon">
        {{t('Pos.Dashboard')}}
      </a-breadcrumb-item>
      <a-breadcrumb-item> {{t('Pos.Product')}}</a-breadcrumb-item>
    </a-breadcrumb>
  </div>

  <div class="btn-container">

    <a-row :gutter="16">
      <a-col :xs="{ span: 24}" :sm="{ span: 12}"  :md="{span: 12}" :lg="{ span: 12 }" :xl="{ span: 12 }" :xxl="{ span: 12 }" class="action-btn-container" >
        <a-button class="add-btn" @click="showAddDrawer">
          {{t('Pos.Add')}}
          <template #icon>
            <InboxOutlined />
          </template>
        </a-button>
      </a-col>
      <!-- New --> 
      <!-- <a-col :xs="{ span: 24}" :sm="{ span: 12}"  :md="{span: 12}" :lg="{ span: 12 }" :xl="{ span: 12 }" :xxl="{ span: 12 }"   class="action-btn-container">
        <a-button class="add-btn" @click="showPackDrawer">
         {{t('Pos.Add')}}
          <template #icon>
            <PlusOutlined />
          </template>
        </a-button>
      </a-col> -->

    </a-row>

    
    
  </div>

  

  <div>
  
    <a-table
      class="table-container"
      :columns="columns"
      :data-source="state.data"
      :pagination="false"
    >

      <template #emptyText>
        <a-empty :description="t('Pos.NoData')" :image="simpleImage" />
      </template>


      <template #bodyCell="{ column, record }">
        <template v-if="column.key === 'actions'">
          <div class="btn-actions"  >

            <a-space>
              <a-tooltip>
                <template #title>{{ t('Pos.View') }}</template>
                <a-button class="view-btn" @click="() => showDisplayDrawer(record)">
                  <template #icon>
                    <EyeOutlined/>
                  </template>
                </a-button>
              </a-tooltip>

              <a-tooltip>
                <template #title>{{ t('Pos.Update') }}</template>

                <a-button type="primary" @click="() => showDrawer(record)">
                  <template #icon>
                    <EditOutlined />
                  </template>
                </a-button>

              </a-tooltip>

              <a-tooltip>
                <template #title>{{ t('Pos.Delete') }}</template>
                <a-button type="primary" danger @click="handleDelete(record)">
                  <template #icon>
                    <DeleteOutlined />
                  </template>
                </a-button>
              </a-tooltip>

              <a-tooltip>
                <template #title>{{ t('Pos.AddVariant') }} </template>
                <a-button class="variant-btn" @click="addVariant(record)">
                  <template #icon>
                    <PlusOutlined /> 
                  </template>
                </a-button>
              </a-tooltip>

              <a-tooltip>
                <template #title>{{ t('Pos.Preferences') }}</template>
                  <a-button class="pref-btn"  @click="showPrefModal(record)">
                  <template #icon>
                    <HeartOutlined />
                  </template>
                </a-button>
              </a-tooltip>

            </a-space>


          </div>
        </template>

        <template v-if="column.key === 'img'">
          <a-row >
            <a-col flex="50px" >

              <img 
               
                class="img-table"
                :src="record.photo"
                
              /> 

             

            </a-col>

           

           
          </a-row>

        </template>

        

        <template v-if="column.key === 'unit_price'">
          
          {{formatPrice(record.unit_price)}}
        </template>

       

        <template v-if="column.key === 'purchase_price'">
          
          {{formatPrice(record.purchase_price)}}
        </template>


      </template>

      <template #customFilterDropdown="{ setSelectedKeys, selectedKeys, confirm, clearFilters, column }">
        <div style="padding: 8px">
          <a-input ref="searchInput" :placeholder="`${column.title}`" :value="selectedKeys[0]"
            style="width: 188px; margin-bottom: 8px; display: block"
            @change="e => setSelectedKeys(e.target.value ? [e.target.value] : [])"
            @pressEnter="handleSearch(selectedKeys, confirm, column.dataIndex)" />

          <a-button type="primary" size="small" style="width: 90px; margin-right: 8px"
            @click="handleSearch(selectedKeys, confirm, column.dataIndex)"
            >
            
            {{ t('Pos.Search') }}
          </a-button>
          <a-button size="small" style="width: 90px" @click="handleReset(clearFilters)">
           {{ t('Pos.Reset') }}
          </a-button>
        </div>
      </template>

      <template #customFilterIcon="{ filtered }">
        <search-outlined :style="{ color: filtered ? '#108ee9' : undefined }" />
      </template>

    </a-table>
    <div class="pagination" >
      <a-pagination class="paginationArrows" v-model:current="currentPage" v-model:pageSize="perPage" :total="state.totalPages"  :default-page-size="perPage" @change="handlePageChange" :showSizeChanger=false direction="ltr" />
      <a-select
        class="select-input"
        v-model:value="selectPagination"
        @change="handleSelectPaginationChange"
        v-if="state.totalPages>10"
      >
        <a-select-option value="10">10 / {{t('Pos.page')}}</a-select-option>
        <a-select-option value="20">20 / {{t('Pos.page')}}</a-select-option>
        <a-select-option value="50">50 / {{t('Pos.page')}}</a-select-option>
        <a-select-option value="100">100 / {{t('Pos.page')}}</a-select-option>
      </a-select>
    </div>

    <!-- update drawer -->
    <a-drawer v-model:open="open" class="custom-class" root-class-name="root-class-name" 
      :width=modalWidth  
      :placement="locale === 'ar' ? 'left' : 'right'"
    >


      <template #title >
        <p :class="{ 'is-rtl': textDirection==='rtl'}">{{t('Pos.UpdateProduct')}}</p>
      </template>

      <a-steps
        :current="currentUpdate"
        :items="itemsUpdate"

      ></a-steps>
      <div class="steps-content">
        <a-form ref="formRef" :model="form" :rules="rules" layout="vertical" :class="{ 'is-rtl': textDirection==='rtl'}">


          <div v-if="stepsUpdate[currentUpdate].content==='first-content'">
            <a-row :gutter="16">
              <a-col :span="24">
                <a-form-item name="name">
                  <template #label>
                    <span class="label-form">{{ t('Pos.Name') }}</span>
                  </template>
                  <a-input v-model:value="form.name" :placeholder="t('Pos.Name')" class="input-form" />
                </a-form-item>
              </a-col>
            </a-row>
            <a-row :gutter="16">
              <a-col :span="12">

               
                <a-form-item name="category_id">
                  <template #label>
                    <span class="label-form">{{ t('Pos.Category') }}</span>
                  </template>

                  <a-select v-model:value="form.category_id" show-search :option-filter-prop="'label'" >
                    <a-select-option v-for="category in state.categories" :key="category.id" :value="category.id"  :selected="category.id === form.category_id ">{{ category.name }}</a-select-option>
                  </a-select>
                </a-form-item>
              </a-col>

              <a-col :span="12">
                <a-form-item  name="manufacturer_id">

                  <template #label>
                    <span class="label-form">{{ t('Pos.Manufacturer') }}</span>
                  </template>

                  <a-select  v-model:value="form.manufacturer_id" show-search :option-filter-prop="'label'" >
                    <a-select-option v-for="item in state.manufacturers" :key="item.id" :value="item.id" :label="item.name" :selected="item.id === form.manufacturer_id ">{{ item.name }}</a-select-option>
                  </a-select>

                </a-form-item>
              </a-col>
            </a-row>
            <a-row :gutter="16">
              <a-col :span="12">
                <a-form-item  name="supplier_id">

                  <template #label>
                    <span class="label-form">{{ t('Pos.Supplier') }}</span>
                  </template>
                  <a-select v-model:value="form.supplier_id" show-search :option-filter-prop="'label'"  >
                    <a-select-option v-for="supplier in state.suppliers" :key="supplier.id" :value="supplier.id" :label="supplier.company_name" :selected="supplier.id === form.supplier_id ">{{ supplier.company_name }}</a-select-option>

                  </a-select>

                </a-form-item>
              </a-col>

              <a-col :span="12">
                <a-form-item name="vat_id">
                  <template #label>
                    <span class="label-form">{{ t('Pos.Vat') }}</span>
                  </template>

                  <a-select  v-model:value="form.vat_id">
                    <a-select-option v-for="vat in state.vats" :key="vat.id" :value="vat.id" :selected="vat.id === form.vat_id ">{{ vat.name }}</a-select-option>
                  </a-select>
                </a-form-item>
              </a-col>
            </a-row>
            <a-row :gutter="16">
              <a-col :span="12">
                <a-form-item name="price">

                  <template #label>
                    <span class="label-form">{{ t('Pos.Price') }}</span>
                  </template>

                  <a-input v-model:value="form.price" :placeholder="t('Pos.Price')" />
                </a-form-item>
              </a-col>
              <a-col :span="12">
                <a-form-item name="purchase_price">
                  <template #label>
                    <span class="label-form">{{ t('Pos.PurchasePrice') }}</span>
                  </template>
                  <a-input v-model:value="form.purchase_price" :placeholder="t('Pos.PurchasePrice')" />
                </a-form-item>
              </a-col>
            </a-row>

            <a-row :gutter="16">
              <a-col :span="12">
                <a-form-item  name="unit">
                  <template #label>
                      <span class="label-form">{{t('Pos.Unit')}}</span>
                  </template>

                  <a-select v-model:value="form.unit"  >
                    <a-select-option value="mg"  >{{t('Pos.Milligrams') }}</a-select-option>
                    <a-select-option value="pcs"  >{{t('Pos.Pieces') }}</a-select-option>
                    <a-select-option value="ml"  >{{t('Pos.Milliliter') }}</a-select-option>
                    <a-select-option value="mm"  >{{t('Pos.Millimeter') }}</a-select-option>
                  </a-select>

                </a-form-item>
              </a-col>

              <a-col :span="12">
                <a-form-item name="display_rank">

                  <template #label>
                    <span class="label-form">{{t('Pos.DisplayRank') }}</span>
                  </template>

                  <a-input v-model:value="form.display_rank" :placeholder="t('Pos.DisplayRank')" />
                </a-form-item>
              </a-col>
            </a-row>
            <a-row :gutter="16">
              <a-col :span="12">
                <a-form-item  name="code_ean">

                  <template #label>
                    <span class="label-form">{{ t('Pos.Code') }}</span>
                  </template>

                  <a-input v-model:value="form.code_ean" :placeholder="t('Pos.Code')" />
                </a-form-item>
              </a-col>
              <a-col :span="12">
                <a-form-item name="barcode">
                  <template #label>
                    <span class="label-form">{{ t('Pos.BarCode') }}</span>
                  </template>
                  <a-input v-model:value="form.barcode" :placeholder="t('Pos.BarCode')" />
                </a-form-item>
              </a-col>
            </a-row>
          </div>

          <div v-if="stepsUpdate[currentUpdate].content==='second-content'">

            <a-row :gutter="16">
              <a-col :span="12">
                <a-form-item  name="production_point_id">
                  <template #label>
                    <span class="label-form">{{ t('Pos.ProductionPoint') }}</span>
                  </template>
                  <a-select  v-model:value="form.production_point_name" show-search  @change="handleProductionPoint" :option-filter-prop="'label'" allowClear>

                    <template #notFoundContent>
                      <a-empty :description="t('Pos.NoData')" :image="simpleImage" />
                    </template>
                    <a-select-option v-for="item in state.productionPoints" :key="item.id" :value="item.id" :selected="item.id === form.production_point_id " :label="item.name">{{ item.name }}</a-select-option>
                  </a-select>                
                </a-form-item>
              </a-col>

              <a-col :span="12">
                <a-form-item  name="fixed_tax">
                  <template #label>
                    <span class="label-form">{{ t('Pos.FixedTax') }}</span>
                  </template>
                  <a-input v-model:value="form.fixed_tax" :placeholder="t('Pos.FixedTax')" class="input-form" />     
                </a-form-item>
              </a-col>

            </a-row>

            <a-row :gutter="16">
              <a-col :span="24">
                <a-form-item name="description">
                  <template #label>
                    <span class="label-form">{{ t('Pos.Description') }}</span>
                  </template>
                  <a-textarea v-model:value="form.description" :rows="4" :placeholder="t('Pos.Description')" />
                </a-form-item>
              </a-col>
            </a-row>

            <a-row>
              <a-col :span="24">
                <a-form-item>
                  <template #label>
                    <span class="label-form">{{ t('Pos.Photo') }}</span>
                  </template>

                  <a-upload-dragger
                    v-model:fileList="fileList"
                    name="file"
                    :multiple="false"
                    :max-count="1"
                    @change="handleUpdateChangeFile"
                    @drop="handleDrop"
                    :before-upload="beforeUpload"
                  >

                    <p class="ant-upload-drag-icon">
                      <InboxOutlined />
                    </p>
                    <p class="ant-upload-text">{{ t('Pos.dropTitle1') }}</p>
                    <p class="ant-upload-hint">
                      {{ t('Pos.dropTitle2') }}
                    </p>

                  </a-upload-dragger>  
                  <template v-if="form.photo!==null && !state.fileChanged">
                    <div>
                      <p class="photo-info" @click="() => setVisible(true)"><PaperClipOutlined /><span class="photo-title"> {{extractFilename(form.photo)}}</span> <DeleteOutlined  @click="deletePhoto" /></p>

                      <a-image
                        :width="200"
                        :style="{ display: 'none' }"
                        :preview="{
                          visible,
                          onVisibleChange: setVisible,
                        }"
                        :src="form.photo"
                      />
                    </div>
                  </template>            
                </a-form-item>
              </a-col>
            </a-row>


            <a-row>
              <a-col :span="12">
                
                <div class="switch-container">
                  <p>{{ t('Pos.refundable') }} </p>
                  <a-switch v-model:checked="form.refundable"/>
                </div>
              
              </a-col>

              <a-col :span="12">
                
                <div class="switch-container">
                  <p>{{ t('Pos.stockable') }} </p>
                  <a-switch v-model:checked="form.stockable"/>
                </div>
              
              </a-col>
             
            </a-row>

            <a-row>
              <a-col :span="12">
                <div class="switch-container">
                  <p>{{ t('Pos.HasIngredients') }}</p>
                  <a-switch v-model:checked="form.has_ingredients"  />
                </div>
              </a-col>
            </a-row>

            <a-row v-if="form.has_ingredients===true">

              <a-col :span="24">
                <a-form-item  name="ingredients_id">
                  <template #label>
                    <span class="label-form">{{t('Pos.Ingredients')}}</span>
                  </template>
                    
                  <a-select
                    v-model:value="form.ingredients_id"
                    mode="multiple"
                    style="width: 100%"
                    :options="optionsUpdate"
                    @change="handleUpdateIngredient"
                    show-search
                    :option-filter-prop="'label'"
                  >

                    <template #notFoundContent>
                      <a-empty :description="t('Pos.NoData')" :image="simpleImage" />
                    </template>
                  
                  </a-select>

                    
                </a-form-item>
              </a-col>
            </a-row>
            <template v-if="form.has_ingredients===true">
              <a-row v-for="item in state.ingrArray" :key="item" :gutter="16" class="ingredients-container">
              
                

                <a-col :xs="{ span: 24}" :sm="{ span: 24}"  :md="{span: 24}" :lg="{ span: 24 }" :xl="{ span: 24 }" :xxl="{ span: 24 }" class="ingr-name-container">
                  <span class="label-form">{{item.ingredient_name}}</span>
                </a-col>

                <a-col :xs="{ span: 24}" :sm="{ span: 24}"  :md="{span: 6}" :lg="{ span: 6 }" :xl="{ span: 6 }" :xxl="{ span: 6 }" class="ingr-content-container">
                  <a-form-item has-feedback  :name="'qtUpdate_' + item.ingredient_id" :validate-status="getValidatingStatus(item.ingredient_id,'qtUpdate')" :help="getValidatingHelp(item.ingredient_id,'qtUpdate')">
                    <a-input :id="'qtUpdate_' + item.ingredient_id" :placeholder="t('Pos.Quantity')" class="input-form" @input="clearError(item.ingredient_id, 'qtUpdate')" v-model:value="item.quantity_used" />
                  </a-form-item>
                </a-col>

                <a-col :xs="{ span: 24}" :sm="{ span: 24}"  :md="{span: 6}" :lg="{ span: 6 }" :xl="{ span: 6 }" :xxl="{ span: 6 }"  class="ingr-content-container">

                  <div class="check-btn"> 
                    <span class="label-form">{{ t('Pos.IncludedbyDefault') }}</span>
                   
                    <a-switch :id="'defaultUpdate_' + item.ingredient_id" v-model:checked="item.included_by_default" class="swicth-btn"/>
                  </div>
                
                </a-col>

                <a-col :xs="{ span: 24}" :sm="{ span: 24}"  :md="{span: 6}" :lg="{ span: 6 }" :xl="{ span: 6 }" :xxl="{ span: 6 }"  class="ingr-content-container">
                  <a-form-item has-feedback :validate-status="getValidatingStatus(item.ingredient_id,'costUpdate')" :help="getValidatingHelp(item.ingredient_id,'costUpdate')" >
                    <a-input v-model:value="item.additional_cost" :id="'costUpdate_' + item.ingredient_id" :placeholder="t('Pos.AdditionalCost')"   class="input-form" @input="clearError(item.ingredient_id, 'costUpdate')" />
                  </a-form-item>
                </a-col>

                <a-col :xs="{ span: 24}" :sm="{ span: 24}"  :md="{span: 6}" :lg="{ span: 6 }" :xl="{ span: 6 }" :xxl="{ span: 6 }"  class="ingr-content-container">
                  <a-form-item
                    :validate-status="getValidatingStatus(item.ingredient_id,'unit')" :help="getValidatingHelp(item.ingredient_id,'unit')"
                  
                  >
                    <a-select :id="'unit_' + item.ingredient_id" :placeholder="t('Pos.Unit')" v-model:value="item.unit" @change="handleUnitChange($event,item.ingredient_id)">
                      <a-select-option value="mg">{{t('Pos.Milligrams') }}</a-select-option>
                      <a-select-option value="pcs">{{t('Pos.Pieces') }}</a-select-option>
                      <a-select-option value="ml">{{t('Pos.Milliliter') }}</a-select-option>
                      <a-select-option value="mm">{{t('Pos.Millimeter') }}</a-select-option>
                    </a-select>
                  </a-form-item>
                </a-col>


                  
                
              </a-row>
            </template>
          </div>

          <!-- <div v-if="stepsUpdate[currentUpdate].content==='third-content'">
            <a-row v-if="form.has_ingredients && form.ingredients_id.length!==0" >

              <a-col :span="24">
                <a-form-item  name="discount_id">
                  <template #label>
                    <span class="label-form">{{t('Pos.Ingredients')}}</span>
                  </template>
                  
                  <a-select
                    v-model:value="form.ingredients_id"
                    mode="multiple"
                    style="width: 100%"
                    :options="optionsUpdate"
                    @change="handleMultiChangeUpdate"
                    show-search
                  ></a-select>

                    
                </a-form-item>
                </a-col>

            </a-row>

           

             

            
            
            
          </div>

          <div v-if="stepsUpdate[currentUpdate].content==='fourth-content'">
            fourth content

           
            
          </div> -->


          

          


        </a-form>
      </div>

      <div class="steps-action" >
        <a-button v-if="currentUpdate < stepsUpdate.length - 1" type="primary" @click="nextUpdate" :class="{ 'is-rtl': textDirection==='rtl'}">{{ t('Pos.Next') }}</a-button>
        <a-button
          v-if="currentUpdate == stepsUpdate.length - 1"
          type="primary"
          @click="onSubmit(form.id)"
          :class="{ 'is-rtl': textDirection==='rtl'}"
        >
          {{ t('Pos.Update') }}
        </a-button>
        <a-button v-if="currentUpdate > 0" style="margin-left: 8px" @click="prevUpdate" :class="{ 'is-rtl': textDirection==='rtl'}"> {{ t('Pos.Previous') }}</a-button>
      </div>


      <!--
      <div class="drawer-footer"> 
        <a-space>
          <a-button  type="primary" @click="onSubmit(form.id)">Update</a-button>
          <a-button type="primary" danger @click="resetForm">Cancel</a-button>
        </a-space>
      </div>
      -->

    </a-drawer>
    <!-- update drawer ->

    <! -- display drawer -->
      <a-drawer
        v-model:open="openDisplay"
        class="custom-class"
        root-class-name="root-class-name"
        :width=modalWidth
        :placement="locale === 'ar' ? 'left' : 'right'"
      >

        <template #title >
          <p :class="{ 'is-rtl': textDirection==='rtl'}">{{t('Pos.ProductInfo')}}</p>
        </template>
      
        <a-form ref="formRef" :model="form" :rules="rules" layout="vertical" :class="{ 'is-rtl': textDirection==='rtl'}">
          <a-collapse
            v-model:activeKey="activeKey"
            :bordered="false"
            class="collapse"
          >

            <template #expandIcon="{ isActive }">
              <CaretRightOutlined :rotate="isActive ? 90 : 0"  class="caret-icon"/>
            </template>

            <a-collapse-panel key="1" :style="customStyle">
              <template #header>
                <div class="custom-header">{{t('Pos.GeneralInfo')}}</div>
              </template>
              <a-row class="photo-container" :gutter="16">
                <a-col flex="150px">
                  <a-image
                    :width="100"
                    :height="100"
                    :src="form.photo"
                    v-if="form.photo!==null"
                  />

                  <img 
                    :width="100"
                    src="@/assets/img/nophoto.png"
                    v-if="form.photo===null"
                  />   
                </a-col>

                <a-col flex="auto" >
                  <a-row class="info info-title">
                    {{form.name}}
                  </a-row>

                  <template v-if="form.description==='null'"></template>
                  <a-row class="info-txt" v-else>
                    {{form.description}}
                  </a-row>
                </a-col>
              </a-row>

              <a-row :gutter="16">
                <a-col :span="12">
                  <p><span class="info-title">{{t('Pos.Category')}} :</span> {{form.category_name}}</p>
                </a-col>

                <a-col :span="12">
                  <p><span class="info-title">{{t('Pos.Manufacturer')}} :</span> {{transformUpperCase(form.manufacturer_name)}}</p>
                </a-col>
              </a-row>

              <a-row :gutter="16">

                <a-col :span="12">
                  <p><span class="info-title">{{t('Pos.Supplier')}} :</span> {{transformUpperCase(form.supplier_name)}}</p>
                </a-col>

                <a-col :span="12">
                  <p><span class="info-title">{{t('Pos.Vat')}} :</span> {{getVATValue(form.vat_id)}} %</p>
                </a-col>

              </a-row>

              <a-row :gutter="16">

                <a-col :span="12">
                  <p><span class="info-title">{{t('Pos.Price')}} :</span> {{form.price}}</p>
                </a-col>

                <a-col :span="12">
                  <p><span class="info-title">{{ t('Pos.PurchasePrice') }} :</span> {{form.purchase_price}}</p>
                </a-col>

              </a-row>

              <a-row :gutter="16">

                <a-col :span="12">
                  <p><span class="info-title">{{t('Pos.DisplayRank')}} :</span> {{form.display_rank}}</p>
                </a-col>

                <a-col :span="12">
                  <p>
                    <span class="info-title">{{ t('Pos.Unit') }} :</span> 
                    <span v-if="form.unit==='mg'">{{t('Pos.Milligrams') }}</span>
                    <span v-if="form.unit==='pcs'">{{t('Pos.Pieces') }}</span>
                    <span v-if="form.unit==='ml'">{{t('Pos.Milliliter') }}</span>
                    <span v-if="form.unit==='mm'">{{t('Pos.Millimeter') }}</span>


                  </p>
                </a-col>

              </a-row>


              <a-row :gutter="16">

                <a-col :span="12">
                  <p><span class="info-title">{{ t('Pos.Code') }} :</span> {{form.code_ean}}</p>
                </a-col>

                <a-col :span="12">
                  <p><span class="info-title">{{ t('Pos.BarCode') }} :</span> {{form.barcode}}</p>
                </a-col>
               
              </a-row>

              <a-row :gutter="16">

                <a-col :span="12">
                  <p><span class="info-title">{{ t('Pos.ProductionPoint') }} :</span> {{form.production_point_name}} </p>
                </a-col>

                <a-col :span="12">
                  <p><span class="info-title">{{ t('Pos.FixedTax') }} :</span> {{form.fixed_tax}} </p>
                </a-col>
              </a-row>

              <a-row>
                <a-col :span="12">
                  <p>
                    <span class="info-title">{{ t('Pos.refundable') }} :</span>
                    <span v-if="form.refundable===1 || form.refundable===true">
                      <check-circle-two-tone two-tone-color="#52c41a" />
                    </span>
                    <span  v-if="form.refundable===0 || form.refundable===false">
                      <close-circle-two-tone two-tone-color="#ff4d4f"/>
                    </span>
                  </p>
                </a-col>

                <a-col :span="12">
                  <p>
                    <span class="info-title">{{ t('Pos.stockable') }} :</span>
                    <span v-if="form.stockable===1 || form.stockable===true">
                      <check-circle-two-tone two-tone-color="#52c41a" />
                    </span>
                    <span  v-if="form.stockable===0 || form.stockable===false">
                      <close-circle-two-tone two-tone-color="#ff4d4f"/>
                    </span>
                  </p>
                </a-col>
              </a-row>



            </a-collapse-panel>
        
            <a-collapse-panel key="2" :style="customStyle" v-if="form.has_ingr===1">
              <template #header>
                <div class="custom-header">{{ t('Pos.Ingredients') }}</div>
              </template>
              <!--
              <template v-for="(ingredient, index) in form.ingredients" :key="index">
                
                <a-descriptions bordered size="small"  class="ingredient-des">
                  
                  <a-descriptions-item :label="t('Pos.Name')" :span="3">{{ ingredient.name }}</a-descriptions-item>
                  <a-descriptions-item :label="t('Pos.Cost') " :span="2">{{ ingredient.additional_cost }}</a-descriptions-item>
                  <a-descriptions-item :label="t('Pos.Unit')">
                    <template v-if="ingredient.unit === 'mg'">{{t('Pos.Milligrams') }}</template>
                    <template v-if="ingredient.unit === 'pcs'">{{t('Pos.Pieces') }}</template>
                    <template v-if="ingredient.unit === 'ml'">{{t('Pos.Milliliter') }}</template>
                    <template v-if="ingredient.unit === 'mm'">{{t('Pos.Millimeter') }}</template>
                  </a-descriptions-item>
                  <a-descriptions-item :label="t('Pos.QuantityUsed')" :span="2">{{ ingredient.quantity_used }}</a-descriptions-item>
                  <a-descriptions-item :label="t('Pos.IncludedbyDefault')">{{ ingredient.included_by_default }}</a-descriptions-item>
                </a-descriptions>
              </template> -->

              <template v-for="(ingredients, category) in form.ingredients" :key="category">
                <h3>{{ category }}</h3>

                <!-- iteration over category -->
                <template v-for="(ingredient, index) in ingredients" :key="index">
                  <a-descriptions bordered size="small" class="ingredient-des">
                    <a-descriptions-item :label="t('Pos.Name')" :span="3">{{ ingredient.ingredient.name }}</a-descriptions-item>
                    <a-descriptions-item :label="t('Pos.Cost')" :span="2">{{ ingredient.additional_cost }}</a-descriptions-item>
                    <a-descriptions-item :label="t('Pos.Unit')">
                      <template v-if="ingredient.unit === 'mg'">{{ t('Pos.Milligrams') }}</template>
                      <template v-if="ingredient.unit === 'pcs'">{{ t('Pos.Pieces') }}</template>
                      <template v-if="ingredient.unit === 'ml'">{{ t('Pos.Milliliter') }}</template>
                      <template v-if="ingredient.unit === 'mm'">{{ t('Pos.Millimeter') }}</template>
                    </a-descriptions-item>
                    <a-descriptions-item :label="t('Pos.QuantityUsed')" :span="2">{{ ingredient.quantity_used }}</a-descriptions-item>
                    <a-descriptions-item :label="t('Pos.IncludedbyDefault')">
                        
                        <span v-if="ingredient.included_by_default===1 || ingredient.included_by_default===true">
                          <check-circle-two-tone two-tone-color="#52c41a" />
                        </span>
                        <span  v-if="ingredient.included_by_default===0 || ingredient.included_by_default===false">
                          <close-circle-two-tone two-tone-color="#ff4d4f"/>
                        </span>
                    </a-descriptions-item>
                  </a-descriptions>
                </template>
              </template>


            </a-collapse-panel>
            
            <a-collapse-panel key="3" :style="customStyle" v-if="form.customData!==null" >
              <template #header>
                <div class="custom-header">{{t('Pos.AdditionalInfo') }}</div>
              </template>

               <a-descriptions bordered size="small"  class="ingredient-des">
                  <a-descriptions-item :label="`t('Pos.Name')${item.name}`" :span="3" v-for="item in form.customFields" :key="item.id">
                    
                    {{ item.name }}
                  </a-descriptions-item>
                  
                </a-descriptions>
              
            </a-collapse-panel>


            <a-collapse-panel key="4" :style="customStyle" v-if="varinats_array.length!==0" >
              <template #header>
                <div class="custom-header">{{t('Pos.Variants') }}</div>
              </template>

              <template v-for="item in varinats_array " :key="item">
                <h3>{{ item.attributeName }}</h3>
                <a-descriptions bordered size="small" class="ingredient-des">
                  <a-descriptions-item :label="t('Pos.Name')" :span="3">{{item.name}}</a-descriptions-item>
                  <a-descriptions-item :label="t('Pos.Quantity')" >{{item.qte}}</a-descriptions-item>
                </a-descriptions>
              </template>

              
            </a-collapse-panel>


          </a-collapse>
        </a-form>


      </a-drawer>
    <!-- display drawer-->
    
    

   


    <!-- add varinat modal -->
    <a-modal v-model:open="openVariant" @cancel="resetVarinatForm"  >

      <template #footer>

        <a-space>
          <a-button key="submit" type="primary"  @click="handleVariantOk" :class="{ 'is-rtl': textDirection==='rtl'}">{{ t('Pos.AddVariant') }}</a-button>
          <a-button key="back" @click="handleCancelVariant" :class="{ 'is-rtl': textDirection==='rtl'}">{{ t('Pos.Cancel') }}</a-button>
        </a-space>
      </template>


      <template #title>
        <p :class="{ 'is-rtl': textDirection==='rtl'}">{{t('Pos.AddVariant') }} </p>
      </template>

      <a-form ref="formRefVarinat" :model="formVarinat" :rules="variantRules" layout="vertical" :class="{ 'is-rtl': textDirection==='rtl'}">
        <a-row :gutter="16">

          <a-col :span="12">
            <a-form-item name="name">
              <template #label>
                <span class="label-form">{{ t('Pos.Name') }}</span>
              </template>
              <a-input  v-model:value="formVarinat.name" :placeholder="t('Pos.Name')" class="input-form"/>
            </a-form-item>
          </a-col>

          <a-col :span="12">
            <a-form-item name="quantity">
              <template #label>
                <span class="label-form">{{ t('Pos.Quantity') }}</span>
              </template>
              <a-input  v-model:value="formVarinat.quantity" :placeholder="t('Pos.Quantity')" class="input-form"/>
            </a-form-item>
          </a-col>

        </a-row>

        <a-row :gutter="16">

          <a-col :span="12">
            <a-form-item name="code">
              <template #label>
                <span class="label-form">{{ t('Pos.Code') }}</span>
              </template>
              <a-input  v-model:value="formVarinat.code" :placeholder="t('Pos.Code')" class="input-form"/>
            </a-form-item>
          </a-col>

          <a-col :span="12">
            <a-form-item name="code_ean">
              <template #label>
                <span class="label-form">{{ t('Pos.CodeEan') }}</span>
              </template>
              <a-input  v-model:value="formVarinat.code_ean" :placeholder="t('Pos.CodeEan')" class="input-form"/>
            </a-form-item>
          </a-col>

        </a-row>
        <a-row>
          <a-col :span="24">
            <a-form-item name="value">
              <template #label>
                <span class="label-form">{{ t('Pos.Options') }}</span>
              </template>

            
              <a-select
                v-model:value="value"
                mode="multiple"
                style="width: 100%"
                @change="handleChange"
                show-search
                :option-filter-prop="'label'"

              
              >

                <template #notFoundContent>
                  <a-empty :description="t('Pos.NoData')" :image="simpleImage" />
                </template>
                <a-select-option v-for="attribute in state.attributes" :key="attribute.id" :value="attribute.id" :label="attribute.name" >{{ attribute.name }}</a-select-option>

              </a-select>

            </a-form-item>
          </a-col>
         
        </a-row>
       
        <a-col v-for="item in selectedItems" :key="item" :span="24">
          <div>
            <a-form-item>
              <template #label>
                <span class="label-form">{{ t('Pos.ValuesOf') }} {{item.attributeName}} </span>
              </template>
              <a-select mode="multiple"  @change="handleChangeSelectChange" show-search>
                <template #notFoundContent>
                  <a-empty :description="t('Pos.NoData')" :image="simpleImage" />
                </template>
                <a-select-option v-for="value in item.values" :key="value.id" :value="value.id" >{{ value.name }}</a-select-option>
              </a-select>
            </a-form-item>
          </div>
        </a-col>

      </a-form>
      

        
    </a-modal>
    <!-- add varinat modal -->



    <!-- add drawer v2 -->
    <a-drawer
      v-model:open="addProd" 
      class="custom-class" 
      root-class-name="root-class-name" 
      :placement="locale === 'ar' ? 'left' : 'right'"
      :width=modalWidth
      @close="resetAdd"
    >

      <template #title >
        <p :class="{ 'is-rtl': textDirection==='rtl'}">{{t('Pos.AddProduct')}}</p>
      </template>

      <a-steps
        :current="current"
        :items="items"
      ></a-steps>


      <div class="steps-content">
        <a-form 
          ref="addFormRef"
          :model="addForm"
          :rules="rules"
          layout="vertical"
          :class="{ 'is-rtl': textDirection==='rtl'}"
        >
          <div v-if="steps[current].content==='first-content'">

            <a-row :gutter="16">
              <a-col :span="24">
                <a-form-item name="name">
                  <template #label>
                    <span class="label-form">{{ t('Pos.Name') }}</span>
                  </template>
                  <a-input v-model:value="addForm.name" :placeholder="t('Pos.Name')" class="input-form" />
                </a-form-item>
              </a-col >
            </a-row>

            <a-row :gutter="16">
              
              <a-col :span="12">
                <a-form-item name="category_id">
                  <template #label>
                    <span class="label-form">{{ t('Pos.Category') }}</span>
                  </template>

                  <a-select v-model:value="addForm.category_id" show-search :option-filter-prop="'label'"  >
                    <a-select-option v-for="category in state.categories" :label="category.name" :key="category.id" :value="category.id"  :selected="category.id === form.category_id ">{{ category.name }}</a-select-option>
                  </a-select>
                </a-form-item>
              </a-col>



              <a-col :span="12">
                <a-form-item  name="manufacturer_id">

                  <template #label>
                    <span class="label-form">{{ t('Pos.Manufacturer') }}</span>
                  </template>

                  <a-select  v-model:value="addForm.manufacturer_id" show-search :option-filter-prop="'label'"  >
                    <a-select-option v-for="item in state.manufacturers" :label="item.name" :key="item.id" :value="item.id" :selected="item.id === form.manufacturer_id ">{{ item.name }}</a-select-option>
                  </a-select>

                </a-form-item>
              </a-col>

            </a-row>

            <a-row  :gutter="16">
              <a-col :span="12">
               
                <a-form-item  name="supplier_id">

                  <template #label>
                    <span class="label-form">{{ t('Pos.Supplier') }}</span>
                  </template>
                  <a-select  v-model:value="addForm.supplier_id" show-search :option-filter-prop="'label'"  >
                    <a-select-option v-for="supplier in state.suppliers" :label="supplier.company_name" :key="supplier.id" :value="supplier.id" :selected="supplier.id === form.supplier_id ">{{ supplier.company_name }}</a-select-option>
                  </a-select>

                </a-form-item>
              
              </a-col>

              <a-col :span="12">
                <a-form-item name="vat_id">
                  <template #label>
                    <span class="label-form">{{ t('Pos.Vat') }}</span>
                  </template>

                  <a-select  v-model:value="addForm.vat_id" show-search :option-filter-prop="'label'" >
                    <a-select-option v-for="vat in state.vats" :label="vat.name" :key="vat.id" :value="vat.id" :selected="vat.id === addForm.vat_id ">{{ vat.name }}</a-select-option>
                  </a-select>
                </a-form-item>
              </a-col>
            </a-row>

            <a-row :gutter="16">
              <a-col :span="12">
                <a-form-item name="price">

                  <template #label>
                    <span class="label-form">{{ t('Pos.Price') }}</span>
                  </template>

                  <a-input v-model:value="addForm.price" :placeholder="t('Pos.Price')" />
                </a-form-item>
              </a-col>
              <a-col :span="12">
                <a-form-item name="purchase_price">
                  <template #label>
                    <span class="label-form">{{ t('Pos.PurchasePrice') }}</span>
                  </template>
                  <a-input v-model:value="addForm.purchase_price" :placeholder="t('Pos.PurchasePrice')" />
                </a-form-item>
              </a-col>
            </a-row>

            <a-row :gutter="16">
              <a-col :span="12">
                <a-form-item  name="unit">
                  <template #label>
                      <span class="label-form">{{t('Pos.Unit')}}</span>
                  </template>

                  <a-select v-model:value="addForm.unit" show-search :option-filter-prop="'label'" >
                    <a-select-option value="mg" :label="t('Pos.Milligrams')">{{t('Pos.Milligrams') }}</a-select-option>
                    <a-select-option value="pcs" :label="t('Pos.Pieces')">{{t('Pos.Pieces') }}</a-select-option>
                    <a-select-option value="ml" :label="t('Pos.Milliliter')">{{t('Pos.Milliliter') }}</a-select-option>
                    <a-select-option value="mm" :label="t('Pos.Millimeter')">{{t('Pos.Millimeter') }}</a-select-option>
                  </a-select>

                </a-form-item>
              </a-col>

              <a-col :span="12">
                <a-form-item name="display_rank">

                  <template #label>
                    <span class="label-form">{{t('Pos.DisplayRank') }}</span>
                  </template>

                  <a-input v-model:value="addForm.display_rank" :placeholder="t('Pos.DisplayRank')" />
                </a-form-item>
              </a-col>
            </a-row>

            <a-row :gutter="16">
              <a-col :span="12">
                <a-form-item  name="code_ean">

                  <template #label>
                    <span class="label-form">{{t('Pos.Code') }}</span>
                  </template>

                  <a-input v-model:value="addForm.code_ean" :placeholder="t('Pos.Code')" />
                </a-form-item>
              </a-col>
              <a-col :span="12">
                <a-form-item name="barcode">
                  <template #label>
                    <span class="label-form">{{t('Pos.BarCode') }} </span>
                  </template>
                  <a-input v-model:value="addForm.barcode" :placeholder="t('Pos.BarCode')" />
                </a-form-item>
              </a-col>
            </a-row>


            


          </div>

          <div v-if="steps[current].content==='second-content'">

            <a-row :gutter="16">
              <a-col :span="12">
                <a-form-item  name="production_point_id">
                  <template #label>
                    <span class="label-form">{{ t('Pos.ProductionPoint') }}</span>
                  </template>
                  <a-select  v-model:value="addForm.production_point_id" show-search :option-filter-prop="'label'"  allowClear>
                    <a-select-option v-for="item in state.productionPoints" :key="item.id" :value="item.id" :label="item.name" :selected="item.id === addForm.production_point_id ">{{ item.name }}</a-select-option>
                  </a-select>                
                </a-form-item>
              </a-col>

              <a-col :span="12">
                <a-form-item name="fixed_tax">
                  <template #label>
                    <span class="label-form">{{ t('Pos.FixedTax') }}</span>
                  </template>
                  <a-input v-model:value="addForm.fixed_tax" :placeholder="t('Pos.FixedTax')" class="input-form" />
                </a-form-item>
              </a-col >

            </a-row>
            
            <a-row :gutter="16">
              <a-col :span="24">
                <a-form-item name="description">

                  <template #label>
                    <span class="label-form">{{ t('Pos.Description') }}</span>
                  </template>
                  <a-textarea v-model:value="addForm.description" :rows="4" :placeholder="t('Pos.Description')" />
                </a-form-item>
              </a-col>
            </a-row>

            <a-row>
              <a-col :span="24">
                <a-form-item>
                  <template #label>
                    <span class="label-form">{{ t('Pos.Photo') }}</span>
                  </template>

                  <a-upload-dragger
                    v-model:fileList="fileList"
                    name="file"
                    :multiple="false"
                    :max-count="1"
                    @change="handleChangeFile"
                    @drop="handleDrop"
                    :before-upload="beforeUpload"
                  >

                    <p class="ant-upload-drag-icon">
                      <InboxOutlined />
                    </p>
                    <p class="ant-upload-text">{{ t('Pos.dropTitle1') }}</p>
                    <p class="ant-upload-hint">
                     {{ t('Pos.dropTitle2') }}
                    </p>

                  </a-upload-dragger>          
                </a-form-item>
              </a-col>
            </a-row>


            <!-- custom fields -->
            
            <a-row v-if="state.customFields !== null" :gutter="16">
             
              <a-col v-for="item in state.customFields" :key="item" :span="12">
                
                <a-row>
                  <a-form-item
                    :name="'customData_' + item.id"
                    v-if="item.type==='text'"
                    :rules="[item.is_mandatory === 1 ? { required: true, message:  t('Pos.RequiredField') } : {}]"
                    class="dynamic-input"
                  >

                    <template #label>
                      <span class="label-form">{{transformUpperCase(item.name)}}</span>
                    </template>
                    <a-input  :id="'txt_' + item.id" />
                  
                  </a-form-item>
                </a-row> 

                
                <a-row>
                  <a-form-item
                    name="customData"
                    :rules="[item.is_mandatory === 1 ? { required: true, message: t('Pos.RequiredField') } : {}]"
                    v-if="item.type==='textarea'"
                    class="dynamic-input"
                  >

                    <template #label>
                      <span class="label-form">{{transformUpperCase(item.name)}}</span>
                    </template>
                    <a-textarea  :id="'area_' + item.id"   :rows="4" />
                  </a-form-item>
                </a-row>
              

              
                <a-row >
                  <a-form-item
                    name="customData"
                    :rules="[item.is_mandatory === 1 ? { required: true, message:  t('Pos.RequiredField') } : {}]"
                    v-if="item.type==='number'"
                    class="dynamic-input"
                  >

                    <template #label>
                      <span class="label-form">{{transformUpperCase(item.name)}}</span>
                    </template>
                    <a-input-number  :id="'number_' + item.id" />
                  </a-form-item>
                </a-row>
              

              
                <a-row >
                  <a-form-item
                    name="customData"
                    :rules="[item.is_mandatory === 1 ? { required: true, message: t('Pos.RequiredField') } : {}]"
                    v-if="item.type==='date'"
                    class="dynamic-input"
                  >
                    <template #label>
                      <span class="label-form">{{transformUpperCase(item.name)}}</span>
                    </template>


                    <a-date-picker show-time  :id="'date_' + item.id" />
                  </a-form-item>
                </a-row>
              
            
              
                <a-row>
                  <a-form-item
                    name="customData"
                    :rules="[item.is_mandatory === 1 ? { required: true, message:  t('Pos.RequiredField') } : {}]"
                    v-if="item.type==='select'"
                    class="dynamic-input"
                  >

                    <template #label>
                      <span class="label-form">{{transformUpperCase(item.name)}}</span>
                    </template>

                    <a-select
                      
                      :id="'select_' + item.id" @change="handleSelectChange" class="dynamic-input"
                    >
                      <template v-for="option in item.options.split(',').filter(option => option.trim() !== '')" :key="option" >
                        <a-select-option  :value="option">{{ option }}</a-select-option>
                      </template>
                    </a-select>

                  </a-form-item>
                </a-row>
              
              
                <a-row>
                  <a-form-item 
                    name="customData"
                    :rules="[item.is_mandatory === 1 ? { required: true, message:  t('Pos.RequiredField') } : {}]"
                    v-if="item.type==='checkbox'"
                  
                  >

                    <template #label>
                      <span class="label-form">{{transformUpperCase(item.name)}}</span>
                    </template>

                    <a-checkbox-group  :id="'check_' + item.id">
                      <template v-for="option in item.options.split(',').filter(option => option.trim() !== '')" :key="option" >
                        <a-checkbox :value="option" style="line-height: 32px">{{ option }}</a-checkbox>
                      </template>
                    </a-checkbox-group>
                  </a-form-item>
                </a-row>
              

                <a-row>
                  <a-form-item 
                    name="customData"
                    :rules="[item.is_mandatory === 1 ? { required: true, message:  t('Pos.RequiredField') } : {}]"
                    v-if="item.type==='radio'"
                  >

                    <template #label>
                      <span class="label-form">{{transformUpperCase(item.name)}}</span>
                    </template>

                    <a-radio-group :id="'radio_' + item.id">
                      <template v-for="option in item.options.split(',').filter(option => option.trim() !== '')" :key="option" >
                        <a-radio :value="option" style="line-height: 32px">{{ option }}</a-radio>
                      </template>
                    </a-radio-group>
                  </a-form-item>
                </a-row>

              
              
              </a-col>
            
            </a-row>
            <!-- end custom fields -->
            <a-row>
              <a-col :xs="{ span: 12}" :sm="{ span: 12}"  :md="{span: 12}" :lg="{ span: 12}" :xl="{ span: 12 }" :xxl="{ span: 12 }">
                <div class="switch-container">
                  <p>{{ t('Pos.refundable') }} :</p>
                  <a-switch v-model:checked="addForm.refundable"/>
                </div>
              </a-col>

              <a-col :xs="{ span: 12}" :sm="{ span: 12}"  :md="{span: 12}" :lg="{ span: 12}" :xl="{ span: 12 }" :xxl="{ span: 12 }">
                <div class="switch-container">
                  <p>{{ t('Pos.stockable') }} :</p>
                  <a-switch v-model:checked="addForm.stockable"/>
                </div>
              </a-col>
             
            </a-row>

            <a-row>
              <a-col :xs="{ span: 24}" :sm="{ span: 24}"  :md="{span: 12}" :lg="{ span: 12}" :xl="{ span: 12 }" :xxl="{ span: 12 }">

                <div class="switch-container">
                  <p>{{ t('Pos.HasIngredients') }} :</p>
                  <a-switch v-model:checked="addForm.has_ingredients"/>
                </div>
              </a-col>
            </a-row>
           
            <div v-if="addForm.has_ingredients">
            
              <a-select mode="multiple" v-model:value="selectedIngredients" style="width: 100%" :placeholder="t('Pos.Ingredients')" @change="handleIngredientChange" show-search  :option-filter-prop="'label'">
                <a-select-option v-for="item in state.ingredients" :key="item.value" :value="item.value" :label="item.label">
                  {{ item.label }}
                </a-select-option>
              </a-select>
            
            </div>

            <a-row v-for="item in state.ingrAddArray" :key="item" :gutter="16" class="ingredients-container" > 
              
              <a-col :xs="{ span: 24}" :sm="{ span: 24}"  :md="{span: 24}" :lg="{ span: 24 }" :xl="{ span: 24 }" :xxl="{ span: 24 }" class="ingr-name-container">
                <span class="label-form">{{item.item_name}}</span>
              </a-col>

              <a-col :xs="{ span: 24}" :sm="{ span: 24}"  :md="{span: 6}" :lg="{ span: 6 }" :xl="{ span: 6 }" :xxl="{ span: 6 }" class="ingr-content-container">
                <a-form-item has-feedback  :name="'qt_' + item.item_id" :validate-status="getValidatingStatus(item.item_id,'qt')" :help="getValidatingHelp(item.item_id,'qt')">
                  <a-input :id="'qt_' + item.item_id" :placeholder="t('Pos.Quantity')" class="input-form" @input="clearError(item.item_id, 'qt')" />
                </a-form-item>
              </a-col>

              <a-col :xs="{ span: 24}" :sm="{ span: 24}"  :md="{span: 6}" :lg="{ span: 6 }" :xl="{ span: 6 }" :xxl="{ span: 6 }"  class="ingr-content-container">

                <div class="check-btn"> 
                  <span class="label-form">{{ t('Pos.IncludedbyDefault') }}</span>
                  
                  <a-switch :id="'default_' + item.item_id" v-model:checked="item.default" class="swicth-btn"/>
                </div>
               
              </a-col>

              <a-col :xs="{ span: 24}" :sm="{ span: 24}"  :md="{span: 6}" :lg="{ span: 6 }" :xl="{ span: 6 }" :xxl="{ span: 6 }"  class="ingr-content-container">
                <a-form-item has-feedback :validate-status="getValidatingStatus(item.item_id,'cost')" :help="getValidatingHelp(item.item_id,'cost')" >
                  <a-input :id="'cost_' + item.item_id" :placeholder="t('Pos.AdditionalCost')"   class="input-form" @input="clearError(item.item_id, 'cost')" />
                </a-form-item>
              </a-col>

              
              <a-col :xs="{ span: 24}" :sm="{ span: 24}"  :md="{span: 6}" :lg="{ span: 6 }" :xl="{ span: 6 }" :xxl="{ span: 6 }"  class="ingr-content-container">
                <a-form-item
                  :validate-status="getValidatingStatus(item.item_id,'unit')" :help="getValidatingHelp(item.item_id,'unit')"
                 
                >
                  <a-select :id="'unit_' + item.item_id" :placeholder="t('Pos.Unit')"  @change="handleUnitChange($event,item.item_id)">
                    <a-select-option value="mg">{{t('Pos.Milligrams') }}</a-select-option>
                    <a-select-option value="pcs">{{t('Pos.Pieces') }}</a-select-option>
                    <a-select-option value="ml">{{t('Pos.Milliliter') }}</a-select-option>
                    <a-select-option value="mm">{{t('Pos.Millimeter') }}</a-select-option>
                  </a-select>
                </a-form-item>
              </a-col>

            </a-row>
           
          </div>

          
        
        

          
        </a-form>

      </div>

     
      <div class="steps-action">

        <a-button v-if="current < steps.length - 1" type="primary" @click="next">{{ t('Pos.Next') }}</a-button>
        <a-button
          v-if="current == steps.length - 1"
          type="primary"
          @click="addProduct(form.id)"
        >
          {{ t('Pos.Add') }}
        </a-button>
        <a-button v-if="current > 0" style="margin-left: 8px" @click="prev"> {{ t('Pos.Previous') }}</a-button>




        <!-- <a-button v-if="current < steps.length - 1" type="primary" @click="next">Next</a-button>
  
        <a-button
          v-if="current === 1 && (items.length===2 && current===1 && hasIngredients==true && passedToStep3===false  )"
          type="primary"
          @click="handleIngredientsChange"
        >
          {{t('Pos.Validate') }}
        </a-button>
        <a-button
          v-if="current === steps.length - 1 && ( hasIngredients==false || current ===2)"
          type="primary"
          @click="addProduct()"
        >
          {{t('Pos.Validate') }}
        </a-button>
        
        <a-button v-if="current > 0" style="margin-left: 8px" @click="prev">{{t('Pos.Previous') }}</a-button> -->
      </div>

        
      
    </a-drawer>

    <!-- add model v2 -->

    <!-- preference modal -->
      <a-modal v-model:open="openPref" @cancel="resetPref" >

        <template #title>
          <p :class="{ 'is-rtl': textDirection==='rtl'}">{{ t('Pos.Preferences') }}</p>
        </template> 

        <template #footer>
          <a-button key="back" @click="resetPref" :class="{ 'is-rtl': textDirection==='rtl'}">{{ t('Pos.Cancel') }}</a-button>
        </template>

        

        <template v-for="item in state.preferences" :key="item">

          <a-space
            style="display: flex; margin-bottom: 8px"
            align="baseline"
            :class="{ 'is-rtl': textDirection==='rtl'}"
          >

            <a-form-item has-feedback  :name="'name' + item.id" :validate-status="getValidatingStatus(item.id,'name')" :help="getValidatingHelp(item.id,'name')">
              <a-input v-model:value="item.preference" :id="'name' + item.id" :placeholder="t('Pos.Preference')" class="input-form"/>
            </a-form-item>

            <a-form-item has-feedback  :name="'desc' + item.id" :validate-status="getValidatingStatus(item.id,'desc')" :help="getValidatingHelp(item.id,'desc')">
              <a-input v-model:value="item.description" :id="'desc' + item.id" :placeholder="t('Pos.Description')" class="input-form"/>
            </a-form-item>
            

            <MinusCircleOutlined class="cancel-pref-icon" @click="showDeletePref(item.id)" />
            <EditOutlined class="edit-pref-icon" @click="updatePreference(item)"/>

          </a-space>
         
        </template>

       

        <a-form
          ref="formRefPref"
          name="dynamic_form_nest_item"
          :model="dynamicValidPrefForm"
          @finish="onFinish"
          
        >
          <a-space
            v-for="(pref, index) in dynamicValidPrefForm.prefs"
            :key="pref.id"
            style="display: flex; margin-bottom: 8px"
            align="baseline"
          >
            <a-form-item
              :name="['prefs', index, 'preference']"
              :rules="{
                required: true,
                message: t('Pos.RequiredField'),
              }"
            >
              <a-input v-model:value="pref.preference" :placeholder="t('Pos.Preference')" />
            </a-form-item>
            <a-form-item
              :name="['prefs', index, 'description']"
              :rules="{
                required: true,
                message: t('Pos.RequiredField'),
              }"
            >
              <a-input v-model:value="pref.description" :placeholder="t('Pos.Description')" />
            </a-form-item>
            <MinusCircleOutlined class="cancel-pref-icon" @click="removePref(pref)" />
          </a-space>
          <a-form-item>
            <a-button type="dashed" block @click="addPrefs">
              <PlusOutlined />
              {{t('Pos.AddPreference')}}
            </a-button>
          </a-form-item>
          <a-form-item v-if="dynamicValidPrefForm.prefs.length!==0">
            <a-button type="primary" html-type="submit">{{t('Pos.Add')}}</a-button>
          </a-form-item>
        </a-form>



       
      </a-modal>
    <!-- preference modal -->


    <!-- pack drawer -->
    <a-drawer
      v-model:open="openPack" 
      class="custom-class" 
      root-class-name="root-class-name" 
      :placement="locale === 'ar' ? 'left' : 'right'"
      :width=modalWidth
      @close="resetAddPack"
    >
    
      <template #title >
        <p :class="{ 'is-rtl': textDirection==='rtl'}">{{t('Pos.AddPack')}}</p>
      </template>

      <a-steps
        :current="curentPack"
        :items="itemsPack"
      ></a-steps>

      <div class="steps-content">
        <a-form ref="packFromRef" :model="packForm" :rules="packRules" layout="vertical" :class="{ 'is-rtl': textDirection==='rtl'}">
          <div v-if="stepsPack[curentPack].content==='first-content-pack'">
            <a-row :gutter="16">
              <a-col :span="24">
                <a-form-item name="name">
                  <template #label>
                    <span class="label-form">{{ t('Pos.Name') }}</span>
                  </template>
                  <a-input v-model:value="packForm.name" :placeholder="t('Pos.Name')" class="input-form" />
                </a-form-item>
              </a-col>
            </a-row>

            <a-row :gutter="16">
              <a-col :span="12">
                <a-form-item name="category_id">
                  <template #label>
                    <span class="label-form">{{ t('Pos.Category') }}</span>
                  </template>

                  <a-select v-model:value="packForm.category_id" show-search :option-filter-prop="'label'">
                    <a-select-option v-for="category in state.categories" :label="category.name" :key="category.id" :value="category.id" :selected="category.id === packForm.category_id ">{{ category.name }}</a-select-option>
                  </a-select>
                </a-form-item>
              </a-col>

              <a-col :span="12">
                <a-form-item name="vat_id">
                  <template #label>
                    <span class="label-form">{{ t('Pos.Vat') }}</span>
                  </template>

                  <a-select  v-model:value="packForm.vat_id" show-search :option-filter-prop="'label'">
                    <a-select-option v-for="vat in state.vats" :label="vat.name" :key="vat.id" :value="vat.id" :selected="vat.id === packForm.vat_id ">{{ vat.name }}</a-select-option>
                  </a-select>
                </a-form-item>
              </a-col>
            </a-row>

            <a-row :gutter="16">
                  <a-col :span="12">
                    <a-form-item name="price">

                      <template #label>
                        <span class="label-form">{{ t('Pos.Price') }}</span>
                      </template>

                      <a-input v-model:value="packForm.price" :placeholder="t('Pos.Price')" />
                    </a-form-item>
                  </a-col>
                  <a-col :span="12">
                    <a-form-item name="purchase_price">
                      <template #label>
                        <span class="label-form">{{ t('Pos.PurchasePrice') }}</span>
                      </template>
                      <a-input v-model:value="packForm.purchase_price" :placeholder="t('Pos.PurchasePrice')" />
                    </a-form-item>
                  </a-col>
            </a-row>

            <a-row :gutter="16">
              <a-col :span="12">
                <a-form-item  name="unit">
                  <template #label>
                      <span class="label-form">{{t('Pos.Unit')}}</span>
                  </template>

                  <a-select v-model:value="packForm.unit" show-search :option-filter-prop="'label'">
                    <a-select-option value="mg" :label="t('Pos.Milligrams')">{{t('Pos.Milligrams') }}</a-select-option>
                    <a-select-option value="pcs" :label="t('Pos.Pieces')">{{t('Pos.Pieces') }}</a-select-option>
                    <a-select-option value="ml" :label="t('Pos.Milliliter')">{{t('Pos.Milliliter') }}</a-select-option>
                    <a-select-option value="mm" :label="t('Pos.Millimeter')">{{t('Pos.Millimeter') }}</a-select-option>
                  </a-select>

                </a-form-item>
              </a-col>

              <a-col :span="12">
                <a-form-item name="display_rank">

                  <template #label>
                    <span class="label-form">{{t('Pos.DisplayRank') }}</span>
                  </template>

                  <a-input v-model:value="packForm.display_rank" :placeholder="t('Pos.DisplayRank')" />
                </a-form-item>
              </a-col>
            </a-row>

            <a-row :gutter="16">
              <a-col :span="12">
                <a-form-item  name="code_ean">

                  <template #label>
                    <span class="label-form">{{t('Pos.Code') }}</span>
                  </template>

                  <a-input v-model:value="packForm.code_ean" :placeholder="t('Pos.Code')" />
                </a-form-item>
              </a-col>
              <a-col :span="12">
                <a-form-item name="barcode">
                  <template #label>
                    <span class="label-form">{{t('Pos.BarCode') }} </span>
                  </template>
                  <a-input v-model:value="packForm.barcode" :placeholder="t('Pos.BarCode')" />
                </a-form-item>
              </a-col>
            </a-row>
          </div>

          <div v-if="stepsPack[curentPack].content==='second-content-pack'">
            
            <a-row :gutter="16">
              <a-col :span="24">
                <a-form-item name="description">

                  <template #label>
                    <span class="label-form">{{ t('Pos.Description') }}</span>
                  </template>
                  <a-textarea v-model:value="packForm.description" :rows="4" :placeholder="t('Pos.Description')" />
                </a-form-item>
              </a-col>
            </a-row>

            <a-row>
              <a-col :span="24">
                <a-form-item>
                  <template #label>
                    <span class="label-form">{{ t('Pos.Photo') }}</span>
                  </template>

                  <a-upload-dragger
                    v-model:fileList="fileListPack"
                    name="file"
                    :multiple="false"
                    :max-count="1"
                    @change="handleChangeFilePack"
                    @drop="handleDropPack"
                    :before-upload="beforeUploadPack"
                  >

                    <p class="ant-upload-drag-icon">
                      <InboxOutlined />
                    </p>
                    <p class="ant-upload-text">{{ t('Pos.dropTitle1') }}</p>
                    <p class="ant-upload-hint">
                     {{ t('Pos.dropTitle2') }}
                    </p>

                  </a-upload-dragger>          
                </a-form-item>
              </a-col>
            </a-row>

            <a-row :gutter="16">

              <a-col :span="24">

                <a-form-item  name="products">
                  <template #label>
                    <span class="label-form">{{ t('Pos.Products') }}</span>
                  </template>

  
                  <a-select
                    v-model:value="state.value"
                    mode="multiple"
                    label-in-value
                    :placeholder="t('Pos.Products')"
                    style="width: 100%"
                    :filter-option="false"
                    :not-found-content="state.fetching ? undefined : null"
                    :options="state.products"
                    @search="fetchProduct"
                    @change="handleProductsSelect"
                  >
                      <template v-if="state.fetching" #notFoundContent>
                          <a-spin size="small" />
                      </template>
                  </a-select>
                    
                    
                </a-form-item>  

              </a-col>

            </a-row>

            <a-row :gutter="16" v-for="item in state.productsPack" :key="item">
              <a-col :xs="{ span: 24}" :sm="{ span: 24}"  :md="{span: 24}" :lg="{ span: 24 }" :xl="{ span: 24 }" :xxl="{ span: 24 }" class="ingr-name-container">
                <span class="label-form">{{item.label}}</span>
              </a-col>
              <a-col :xs="{ span: 24}" :sm="{ span: 24}"  :md="{span: 6}" :lg="{ span: 6 }" :xl="{ span: 6 }" :xxl="{ span: 6 }" class="ingr-content-container">
                <a-form-item has-feedback  :name="'qt_' + item.value" :validate-status="getValidatingStatus(item.value,'qt')" :help="getValidatingHelp(item.value,'qt')">
                  <a-input :id="'qt_' + item.value" :placeholder="t('Pos.Quantity')" v-model:value="item.quantity" class="input-form" />
                </a-form-item>
              </a-col>

              <a-col :xs="{ span: 24}" :sm="{ span: 24}"  :md="{span: 8}" :lg="{ span: 8 }" :xl="{ span: 8 }" :xxl="{ span: 8 }"  class="ingr-content-container">

                <div class="check-btn"> 
                  <span class="label-form">{{ t('Pos.multipleChoice') }}</span>
                  <a-switch :id="'choice_' + item.value" v-model:checked="item.choice" class="swicth-btn"/>
                </div>
               
              </a-col>

              <a-col v-if="item.choice === true"  :xs="{ span: 24}" :sm="{ span: 24}"  :md="{span: 10}" :lg="{ span: 10 }" :xl="{ span: 10 }" :xxl="{ span: 10 }"  class="ingr-content-container">
                
                   
                <a-select
                  :id="'choices_' + item.value"
                  v-model:value="item.selectedChoices"
                  mode="multiple"
                  label-in-value
                  :placeholder="t('Pos.selectChoice')"
                  style="width: 100%"
                  :filter-option="false"
                  :not-found-content="item.fetchingChoices ? undefined : null"
                  :options="item.choices"
                  @search="(value) => fetchChoices(item, value)"
                  @change="(value) =>handleChoiceSelect(item, value)"
                >
                  <template v-if="item.fetchingChoices" #notFoundContent>
                    <a-spin size="small" />
                  </template>
                </a-select>



              </a-col>


            
            </a-row>

          </div>

        </a-form>
      </div>

      <div class="steps-action">
        <a-button v-if="curentPack < stepsPack.length - 1" type="primary" @click="nextPack"> {{ t('Pos.Next') }} </a-button>
        <a-button
          v-if="curentPack == stepsPack.length - 1"
          type="primary"
         @click="addPack"
        >
          {{ t('Pos.Add') }}
        </a-button>
        <a-button v-if="curentPack > 0" style="margin-left: 8px" @click="prevPack"> {{ t('Pos.Previous') }} </a-button>
      </div>

    </a-drawer>
    

  </div>
</template>

<script lang="ts">
import { defineComponent, ref, reactive, onMounted, toRaw, watch, computed } from 'vue';
import type { Rule } from 'ant-design-vue/es/form';
import type { FormInstance } from 'ant-design-vue';
import Swal from 'sweetalert2';
import { notification } from 'ant-design-vue';
import { productService } from '../../_services/product.service';
import { categoryService } from '../../_services/category.service';
import { supplierService } from '../../_services/supplier.service';
import { manufacturerService } from '../../_services/manufacturer.service';
import { useRouter } from 'vue-router';
import type { UploadChangeParam,UploadFile } from 'ant-design-vue';
import {
  EyeOutlined,
  DeleteOutlined,
  SearchOutlined,
  InboxOutlined,
  EditOutlined,
  CaretRightOutlined,
  PlusOutlined,
  FileOutlined,
  PaperClipOutlined,
  CheckCircleTwoTone,
  CloseCircleTwoTone,
  MinusCircleOutlined,
  HeartOutlined,
  

  

} from '@ant-design/icons-vue';
import LbrxLoaderSpinner from '../../components/LbrxLoaderSpinner.vue';
import { ingredientsService, vatrateService, customFieldsService, variantService, attributeService, attributeValuesService, productionPointService, preferenceService } from '@/_services';
import { useI18n } from 'vue-i18n';
import { Empty } from 'ant-design-vue';

export default defineComponent({
  name: 'indexProducts',
  components: {
    EyeOutlined,
    DeleteOutlined,
    SearchOutlined,
    InboxOutlined,
    LbrxLoaderSpinner,
    EditOutlined,
    CaretRightOutlined,
    PlusOutlined,
    PaperClipOutlined,
    CheckCircleTwoTone,
    CloseCircleTwoTone,
    MinusCircleOutlined,
    HeartOutlined
    

  },


  setup() {
    const modalWidth = ref(720);
    const simpleImage = Empty.PRESENTED_IMAGE_SIMPLE;

    const selectPagination = ref('10');

    const { t } = useI18n();
    const loading = ref(false);

    const router = useRouter();

    const navigatePage = (link: string) => {
      router.push(link); 
    };

    const open = ref<boolean>(false);
    const openAdd = ref<boolean>(false);
    const addProd = ref<boolean>(false);


    interface IngrAddItem  {
      item_id: string; 
      item_name: string; 
    }
    const state = reactive({
      searchText: '',
      searchedColumn: '',
      data: [] as any[],
      categories:[] as any[],
      manufacturers:[] as any[],
      suppliers:[] as any[],
      vats:[] as any[],
      totalPages:0,
      ingredients:[] as { value: number; label: string }[],
      productHasIngr: 0,
      customFields: [] as any[],
      varinats: [] as any[],
      attributes:[] as any[],
      attributeValues:[] as any[],
      optionsUpdate:[] as any[],
      productionPoints:[] as any[],

      ingrAddArray:[] as any[],
      ingrArray:[] as any[],
      fileChanged:false,

      beginSearch:false,
      searchName:'',

      preferences: [] as any[],

      productId:'',

      products: []  as any[],
      fetching: false,
      value: [] as any[],
      productsPack:[] as any[],

      choices : [] as any[],
      fetchingChoices:false,
      valueChoices: [] as any[],
     

      


      



    
    });

    //stepper : 
    const current = ref<number>(0);
    const goNext = ref(false);


    const next = () => {
      
      
      addFormRef.value.validate().then(()=>{
        console.log('valid');
        current.value++;

      }).catch((error:any) => {
        console.error('Validation error:', error);
      });
    
      
      
      
    };

    const prev = () => {
      current.value--;
    };

    const steps = [
      {
        title: t('Pos.Step1'),
        content: 'first-content',

      },
      {
        title: t('Pos.Step2'),
        content: 'second-content',

      }
    ];
    const items = steps.map(item => ({ key: item.title, title: item.title }));
    
    //update stepper

    const stepperUpdateRef = ref(0);

    const forceRerender = () => {
      stepperUpdateRef.value += 1;
    };

    let currentUpdate = ref<number>(0);
    let goNextUpdate = ref(false);

    const nextUpdate = () => {

      formRef.value.validate().then(()=>{
        console.log('valid');
       currentUpdate.value++;

      

      }).catch((error:any) => {
        console.error('Validation error:', error);
      });
      
     
    };

    const prevUpdate = () => {
      currentUpdate.value--;
    };

    let stepsUpdate=  [
      {
        title: t('Pos.Step1'),
        content: 'first-content',

      },
      {
        title: t('Pos.Step2'),
        content: 'second-content',

      }
    ];
    let itemsUpdate = stepsUpdate.map(item => ({ key: item.title, title: item.title }));
    
    const onUpdateDrawerClose=()=>{
      console.log('reset stepper');
      
      stepsUpdate=[
        {
          title: 'Step 1',
          content: 'first-content',

        },
        {
          title: 'Step 2',
          content: 'second-content',

        }
      ];
      currentUpdate.value=0;
      itemsUpdate = stepsUpdate.map(item => ({ key: item.title, title: item.title }));
    }

    
    //pagination
    const perPage = ref<number>(10);
    const currentPage = ref<number>(1);

    const rules: Record<string, Rule[]> = {
      name: [{ required: true, message:  t('Pos.RequiredField') }],
      category_id: [{ required: true, message:  t('Pos.RequiredField') }],
      manufacturer_id: [{ required: true, message: t('Pos.RequiredField') }],
      price: [
        { required: true, message:  t('Pos.RequiredField') },
        { 
          validator: (_, value) => {
            if (!isNaN(value)) {
              return Promise.resolve();
            }
            return Promise.reject( t('Pos.numericField'));
          }
        }
      ],
      purchase_price: [
        { required: true, message:  t('Pos.RequiredField') },
        {
          validator: (_, value) => {
            if (!isNaN(value)) {
              return Promise.resolve();
            }
            return Promise.reject(t('Pos.numericField'));
          }
        }
      ],
      display_rank: [
       
        {
          validator: (_, value) => {
            if (!isNaN(value)) {
              return Promise.resolve();
            }
            return Promise.reject(t('Pos.numericField'));
          }
        }
      ],
      supplier_id: [{ required: true, message: t('Pos.RequiredField') }],
      code_ean :[{ required: true, message: t('Pos.RequiredField') }],
      barcode :[{ required: true, message: t('Pos.RequiredField') }],
      vat_id :[{ required: true, message: t('Pos.RequiredField') }],
      unit :[{ required: true, message: t('Pos.RequiredField') }],
      ingredients_id: [{ required: true, message: t('Pos.RequiredField') }],


      


    };

    const variantRules: Record<string, Rule[]> = {
      name: [{ required: true, message:  t('Pos.RequiredField') }],
      quantity: [
        { required: true, message:  t('Pos.RequiredField') },
        { 
          validator: (_, value) => {
            if (!isNaN(value)) {
              return Promise.resolve();
            }
            return Promise.reject( t('Pos.numericField'));
          }
        }
      ],
      //value: [{ required: true, message:  t('Pos.RequiredField') }],

    };


     

    const addForm = reactive({
      name: '',
      description: '',
      purchase_price: '',
      price: '',
      category_id: '',
      manufacturer_id: '',
      supplier_id: '',
      vat_id: '',
      code_ean: '',
      refundable:false,
      has_ingredients: false,
      ingredients:'',
      included_by_default:false,
      barcode: '',
      display_rank:'',
      unit:'',
      has_varinats:false,
      production_point_id:'',
      fixed_tax:'',
      stockable:false,
      tableDisplay:"",
    });

    interface IngredientInfo {
      ingredient_id: number;
      included_by_default: boolean;
      additional_cost: string;
      quantity_used: string;
      unit: string;
      ingredient_name: string;
    }

    const form = reactive({
      id:'',
      name: '',
      description: '',
      purchase_price: '',
      price: '',
      category_id: '',
      category_name:'',
      manufacturer_id: '',
      manufacturer_name:'',
      supplier_id: '',
      supplier_name: '',
      vat_id: '',
      code_ean: '',
      has_ingredients: false,
      refundable:false,
      has_ingr:0,
      barcode: '',
      photo:'',
      ingredients:{},
      custom_fields:[],
      display_rank:'',
      unit:'',
      customData:'',
      ingredients_id:[] as string[],
      ingredients_name:[] as string[],
      production_point_id:'',
      production_point_name:'',
      ingredients_info:[] as IngredientInfo[],
      fixed_tax:'',

      target_id:'',
      stockable:false,
    });

    const formVarinat = reactive({
      attribute_id:'',
      quantity:'',
      name:'',
      code:'',
      code_ean:'',

    })

    const searchInput = ref<HTMLInputElement | null>(null);

    const columns = [

      {
        title:"",
        dataIndex: 'img',
        key: 'img',
      },
      {
        title: t('Pos.Name'),
        key: 'name',
        dataIndex: 'name',
        customFilterDropdown: true,
        onFilterDropdownOpenChange: (visible: boolean) => {
          if (visible && !searchInput.value) {
            setTimeout(() => {
              const input = document.querySelector('.ant-table-filter-dropdown input');

              if (input instanceof HTMLInputElement) {
                searchInput.value = input;
                searchInput.value.focus();
              }
            }, 100);
          }
        },
      },
      {
        title: t('Pos.FixedTax'),
        key: 'fixed_tax',
        dataIndex: 'fixed_tax',
      },
      {
        title: t('Pos.Price'),
        key: 'unit_price',
        dataIndex: 'unit_price',
      },
      {
        title:  t('Pos.PurchasePrice'),
        key: 'purchase_price',
        dataIndex: 'purchase_price',
      },
      {
        title:t('Pos.Actions'),
        dataIndex: 'actions',
        key: 'actions',
      },

    ];

    const data = [...Array(100)].map((_, i) => ({
      key: i,
      name: `Product ${i}`,
      description: 'Product',
      price: 200,
      purchase_price: 50,
      category: 'Category 1',
      manufacturer: 'Manufacturer 1',
      tva: 5,
      barcode: '',
      has_ingeredients: false
    }));

  
    //drawer update 
    const resetStepperUpdate=()=>{
      
      console.log('reset stepper update');
      
      currentUpdate.value=0;

      stepsUpdate=[
        {
          title: 'Step 1',
          content: 'first-content',

        },
        {
          title: 'Step 2',
          content: 'second-content',

        }
      ];
      itemsUpdate = stepsUpdate.map(item => ({ key: item.title, title: item.title }));
      console.log('---------------------');
      console.log('current steps', stepsUpdate);
      console.log('current itemsUpdate', itemsUpdate);
      console.log('current index',currentUpdate.value);
    }


    const afterOpenChange = (bool: boolean) => {
      console.log('after open change');
    };

    const afterOpenChangeUpdate = (bool: boolean) => {
      console.log('after open change update', bool);

      //state.ingrArray=[];

    };

  
   


    //update drawer
    let optionsUpdate=ref();

    const getIngredients=()=>{

      ingredientsService.getAll().then((res)=>{
        state.optionsUpdate=res.data;

        optionsUpdate.value = res.data.map((ingr:any) => ({
          value: ingr.id,
          label: ingr.name,
        
        }));
        
      });
      
    }

    let updatedIngredients="" as string |null;
    let newIngredients: { type: string; name: string; additional_cost: string; unit: string; quantity_used: string; included_by_default: number; }[]=[];
    const handleMultiChangeUpdate=(value:any[])=>{
        console.log(`selected update ${value}`);
        const selectedIds = value.map(item => item);
        updatedIngredients=JSON.stringify(selectedIds);

       

        selectedIds.map(id=>{
          state.optionsUpdate.map(item=>{
            if(item.id===id){
              (form.ingredients as any[]).forEach(ingredient=>{
                if(ingredient.name===item.name){
                  newIngredients.push(ingredient);
                }
              });
              
            }
          })
          
          // if(state.optionsUpdate.includes(id)){
          //   const name=state.optionsUpdate.find(id);
          //   console.log('name', name);
          // }
         

        });



      //   const newIngredients = selectedIds.map(id => {
      //   if (state.optionsUpdate.includes(id)) {
            
      //     const selectedIngredient = value.find(item => item.id === id);
      //     console.log('selected ingredient', selectedIngredient);
      //     if (state.optionsUpdate.some(option => option.name === selectedIngredient.name)) {
      //         return {
      //           type: selectedIngredient.type,
      //           name: selectedIngredient.name,
      //           additional_cost: selectedIngredient.additional_cost,
      //           unit: selectedIngredient.unit,
      //           quantity_used: selectedIngredient.quantity_used,
      //           included_by_default: 1, 
      //         };
      //     } else {
      //       return {
      //         type: '', 
      //         name: '', 
      //         additional_cost: '', 
      //         unit: '', 
      //         quantity_used: '', 
      //         included_by_default: 1,
      //       };
      //     }
      //   } else {
      //     return null;
      //   }
      // }).filter(item => item !== null); 



        // newIngredients = selectedIds.map(id => {
        //   if (!state.optionsUpdate.includes(id)  ) {
        //       return {
        //           type: '', 
        //           name: '', 
        //           additional_cost: '', 
        //           unit: '', 
        //           quantity_used: '', 
        //           included_by_default: 1,
        //       };
        //   } else {
           
              
        //     return null;
        //   }
        // }).filter(item => item !== null);

        console.log('updated ingredients', updatedIngredients);
        console.log('form ingredients', form.ingredients);
        console.log('new ingredients', newIngredients);

    }

    
    const handleUpdateIngredient=(value:any[])=>{
      console.log('selected ingr update',value );

      state.ingrArray = state.ingrArray.filter((item) => value.includes(item.item_id));
      if(value){
        value.forEach((itemId)=>{
          const selectedItem=form.ingredients_info.find((option:any)=>option.ingredient_id===itemId);
          if(selectedItem && !state.ingrArray.some((itm:any)=>itm.id===itemId)    ){
            // add dinto ingrArray with additional info
            state.ingrArray.push({
              ingredient_id:itemId,
              ingredient_name:selectedItem.ingredient_name,
              additional_cost:selectedItem.additional_cost,
              unit: selectedItem.unit,
              quantity_used:selectedItem.quantity_used,
              included_by_default:selectedItem.included_by_default,
            })
          }else if( !selectedItem && !state.ingrArray.some((itm:any)=>itm.id===itemId)){
            //add into ingrArray with id and name 
            const newItem= optionsUpdate.value.find((opt:any)=>opt.value===itemId)
            state.ingrArray.push({
              ingredient_id:itemId,
              ingredient_name:newItem.label,
            })
          }
        })
      }

      //state.ingrArray = state.ingrArray.filter((item) => value.includes(item.item_id));
     
      // if(value){
      //   value.forEach((itemId)=>{
      //     const selectedItem = form.ingredients_info.find((option: any) => option.ingredient_id === itemId);
      //     if (selectedItem && !state.ingrArray.some((item) => item.item_id === itemId) ) {
      //       state.ingrArray.push({
              
      //         item_id: itemId,
      //         item_name: selectedItem.ingredient_name,
      //         included_by_default:selectedItem.included_by_default,
      //         additional_cost:selectedItem.additional_cost,
      //         quantity_used:selectedItem.quantity_used,
      //         unit:selectedItem.unit,
              
      //       });
      //     }else if( )

            
      //   })
      // }

      console.log('ingr array', state.ingrArray);


    }


    const showDrawer = async (record: any) => {
      updateModalWidth();
      //resetStepper();  
      currentUpdate.value=0;
      console.log('open update drawer');
      await getProductById(record.id);
     
      // if(state.productHasIngr===1){
      //   console.log('add step');
      //   addStepsUpdate();
      //   setTimeout(() => {
      //     stepperUpdateRef.value=1;


         
      //   }, 3000);
        
      // }else{
      //   console.log('reset step')
      //   resetStepperUpdate();
      //   setTimeout(() => {
      //     stepperUpdateRef.value=1;


      //     getProductionPoints();
      //     getIngredients();
      //     open.value = true;
      //   }, 3000);
      // }

      getProductionPoints();
      getIngredients();
      open.value = true;
      
      
      

      
    };

    const openDisplay = ref<boolean>(false);
    const showDisplayDrawer =(record:any)=>{
      updateModalWidth();
      openDisplay.value = true;
      getProductById(record.id);
      getCustomFields();
    }

    const afterOpenAddChange = (bool: boolean) => {
      console.log('open', bool);
    };

    const showOpenDrawer = (record: any) => {
     

      openAdd.value = true;
    };

    //drawer with stepper

    const afterAddChange = (bool: boolean) => {
      console.log('open', bool);
    };

    const showAddDrawer = (record: any) => {
      updateSelectPlacement();
      updateModalWidth();
      getCustomFields();
      getProductionPoints();
      

      addProd.value = true;


    };

    const filter=(value:string)=>{
      loading.value=true;
      currentPage.value=1;
      productService.getProductsByNameWithPagination(value,currentPage.value, perPage.value)
        .then((res) => {
          
          state.data=res.data;
          state.totalPages = res.meta.total;

          state.beginSearch=true;
          state.searchName=value;
          loading.value=false;
        }).catch((err:any) => {
          console.log(err)
        });

    }

   

    const handleSearch = (selectedKeys:any, confirm:any, dataIndex:any) => {
      confirm();
      state.searchText = selectedKeys[0];
      state.searchedColumn = dataIndex;

      if (dataIndex === 'name' && state.searchText) {

        
        filter(state.searchText);
      }

    };

    const handleReset = (clearFilter: (arg: any) => void) => {
      clearFilter({ confirm: true });
      state.searchText = '';
      currentPage.value=1;
      state.beginSearch=false;
      state.searchName='';
      perPage.value=10;
      selectPagination.value='10';
      
        
      getProducts();
    };

    //form
    const formRef = ref();
    const addFormRef = ref();

    const formRefVarinat = ref();


    
    const resetForm = () => {
      console.log("begin rest");
      formRef.value.resetFields();
      form.production_point_name='';
      form.ingredients='';
      
    };

   
    const resetAdd = () => {
      console.log("begin rest");
      addFormRef.value.resetFields();
      addForm.name="",
      addForm.category_id="",
      addForm.manufacturer_id="",
      addForm.supplier_id="",
      addForm.vat_id="",
      addForm.price="",
      addForm.purchase_price="",
      addForm.unit="",
      addForm.display_rank="",
      addForm.code_ean="",
      addForm.barcode="",
      addForm.has_ingredients=false;
      addForm.refundable=false;

      current.value=0;
      state.ingrAddArray=[];
      fileList.value=[];

      validationHelp.value={};
      validationStatus.value={};
      selectedIngredients.value=[];

    }

    //select variants
    const selectedVatinats = ref<any[]>([]);
    let addInput=false;
    const addNewFiled=(value:string)=>{
      addInput=true;
    }

    const handleVarinatsChange = (value: string[]) => {
      console.log(`selected ${value}`);
    };

    



    //multiselect ingredients
    const selectedIngredients = ref<any[]>([]);
    const selectedKeysIngredients = ref<number[]>([]);

    //old version 
    const handleIngredientChangesss=(ingredients:[])=>{
      selectedIngredients.value = ingredients;
      selectedKeysIngredients.value = selectedIngredients.value
        .map(ingredient => {
          const selectedItem = state.ingredients.find(item => item.label === ingredient);
          return selectedItem ? selectedItem.value : null;
        })
        .filter(value => value !== null) as number[]; 

      console.log('selected keys', selectedKeysIngredients.value);
      console.log('selected ingredientsss : ', selectedIngredients.value);
    }

    const handleIngredientChange=(value:any[])=>{
      console.log(`selected ${value}`);
      state.ingrAddArray = state.ingrAddArray.filter((item) => value.includes(item.item_id));

      if(value){
        value.forEach((itemId)=>{
          const selectedItem = state.ingredients.find((option: any) => option.value === itemId);
           if (selectedItem && !state.ingrAddArray.some((item) => item.item_id === itemId) ) {
              state.ingrAddArray.push({
                
                item_id: itemId,
                item_name: selectedItem.label,
                default:false,

                
              });
            }
        })
      }

      console.log('ingr array', state.ingrAddArray);
    }

    //add dynmaic ingredient : 
    const addNewIngredient = () => {
     console.log("adding new ingredient");
    };

    const refFrom = ref<FormInstance>();

    interface IngredientItem { 
      ingredient_id:number,
      ingredient_name:string,
      included_by_default:boolean,
      additional_cost:number,
      quantity_used:number,
      unit:string
    }

    const dynamicValidateForm = reactive<{ ingredients: IngredientItem[] }>({
      ingredients: [],
    });

    const addNew = () => {
      console.log('selected key ingrd', selectedKeysIngredients)
      for (let i = 0; i < selectedIngredients.value.length; i++) {

        dynamicValidateForm.ingredients.push({
        ingredient_id: selectedKeysIngredients.value[i],
        ingredient_name: selectedIngredients.value[i],
        included_by_default:true,
        additional_cost:null as unknown as number,
        quantity_used:null as unknown as number,
        unit:'',
        });
      }
      
    };

    const removeIngredient = (item: IngredientItem) => {
      console.log('remove ingr');
      const index = dynamicValidateForm.ingredients.indexOf(item);
      if (index !== -1) {
        dynamicValidateForm.ingredients.splice(index, 1);
      }
    };


    const addStepper=()=>{
      let step={
        title:'Step 3',
        content: 'third-content',
      }
      
      steps.push(step);
      current.value++;
    }

    const addStepsUpdate=()=>{
      let step={
        title:'Step 3',
        content: 'third-content',
      }
      stepsUpdate.push(step);
      currentUpdate.value++;
      itemsUpdate.push({ key: 'Step 3', title: 'Step 3' });
      console.log('---------------------');
      console.log('current steps',stepsUpdate );
      console.log('current itemsUpdate', itemsUpdate);
      console.log('current index',currentUpdate.value);
    }

    const addIngrStepsUpdate=()=>{
      console.log('adding ingr')
      let step={
        title:'Step 3',
        content: 'fourth-content',
      }
      stepsUpdate.push(step);
      console.log('steps', stepsUpdate);
      currentUpdate.value++;
      itemsUpdate.push({ key: 'Step 3', title: 'Step 3' });
     
    }

    const hasIngredients = ref(false);

    const handleHasIngredients = ()=>{
      if(form.has_ingredients ===false){
        hasIngredients.value=false;
        const removedStepIndex = steps.findIndex(step => step.content === 'third-content');
        if (removedStepIndex !== -1) {
          steps.splice(removedStepIndex, 1);
        }
        const removedItemIndex = items.findIndex(item => item.key === 'Step 3');
        if (removedItemIndex !== -1) {
          items.splice(removedItemIndex, 1);
        }
        current.value--;
        console.log('steps', steps);
        console.log('items', items); 
      }else{
        hasIngredients.value=true;
      }
    }
    const passedToStep3 = ref(false);
    const handleIngredientsChange = () => {
      console.log('checked', addForm.has_ingredients );
      if (addForm.has_ingredients ===true) {
        addStepper(); 
        items.push({ key: 'Step 3', title: 'Step 3' });
        
        console.log('steps', steps);
        console.log('items', items);

        console.log('selected ingredients', selectedAddIngredients);
        passedToStep3.value=true;
        addNew();
      } else {
        
        //add product 
        console.log('add product');
       
      }
    };

    const addP=()=>{
      console.log('add p ;) ');
      let payload={
        name:addForm.name,
        description:addForm.description,
        purchase_price:addForm.purchase_price,
        price:addForm.price,
        category_id:addForm.category_id,
        manufacturer_id:addForm.manufacturer_id,
        supplier_id:addForm.supplier_id,
        vat_id:addForm.vat_id,
        barcode:addForm.barcode,
        code_ean:addForm.code_ean,
        has_ingredients:addForm.has_ingredients,
      }
     
      
      console.log('payload', payload);
      console.log('added ingredients dynamic',dynamicValidateForm.ingredients);
    }
    

    let selectedAddIngredients="";

    interface Ingredient {
      value: string;
    }
    const addIngredient = ref<Ingredient[]>([]);

    const handleAddChange=(ingredient:string)=>{
      selectedAddIngredients=ingredient
      console.log('selected', selectedAddIngredients);
    }


    const selectRef = ref<HTMLSelectElement | null>(null);

   
    const currentScrol = ref<number>(1);
    const getAllIngredients = ()=>{
      ingredientsService.getAll().then((response)=>{
        
        state.ingredients = response.data.map((ingredient:any) => ({
          value: ingredient.id,
          label: ingredient.name, 
        }));

      });
    }

   


    

  

    watch(selectRef, (newRef) => {
      if (newRef) {
        newRef.addEventListener('scroll', handleScroll);
      }
    });

    const handleScroll = () => {
      
      const selectElement = selectRef.value;
      if (selectElement) {
        if (selectElement.offsetHeight + selectElement.scrollTop >= selectElement.scrollHeight) {
          currentScrol.value++;
          getAllIngredients();
        }
      }
    };

  


  


   


    //loader
    const loaderVisible = ref(true);

    //notification
    const showSuccessNotification  = (message:string)=>{
      notification.success({
        message: message,
        duration: 3,
        top: '80px',
        style:{
        backgroundColor: '#bfffca8a',
        color: 'white'
        }
      });
    };



    const getProducts=()=>{
      loading.value = true;
      productService.getProducts(currentPage.value, perPage.value).then((response)=>{
        state.data=response.data;
        state.totalPages=response.meta.total;
        
      }).catch((error)=>{
        console.log("error api : get products", error);
      })
      .finally(()=>{
        loading.value = false;
      });
    }

    const onShowSizeChange = (current: number, pageSize: number) => {
      console.log(current, pageSize);
      perPage.value=pageSize;

      loading.value=true;
      productService.getProducts(currentPage.value,perPage.value).then((res)=>{
        state.data=res.data;
      }).catch((err:any) => {
        console.log(err)
      }).finally(()=>{
        loading.value = false;
      });
    
    };

    const handleSelectPaginationChange=(selectedItem:any)=>{
      console.log('selected Per page:', selectedItem);
      perPage.value=Number.parseInt(selectedItem);
      currentPage.value=1;

      if(state.beginSearch===true){

         
        if(state.searchName!==''){
          loading.value = true;
          productService.getProductsByNameWithPagination(state.searchName,currentPage.value.toString(),perPage.value).then((res)=>{
            console.log('res', res.data);
            state.data=res.data;
          }).catch((err:any) => {
            console.log(err)
          }).finally(()=>{
            loading.value = false;
          });

        }

      }else{

        loading.value=true;
        productService.getProducts(currentPage.value, perPage.value).then((res)=>{
          state.data=res.data;
          state.totalPages=res.meta.total;
        }).catch((err:any) => {
          console.log(err)
        }).finally(()=>{
          loading.value = false;
        });

      }

            
    }



    
    const handlePageChange = (newPage:any, pageSize:number) => {
      currentPage.value = newPage;
      perPage.value=pageSize;
      

      if(state.beginSearch===true){
        
        state.data=[];

        loading.value = true;
        productService.getProductsByNameWithPagination(state.searchName,currentPage.value, perPage.value)
        .then((res)=>{
          state.data=res.data;
          state.totalPages=res.meta.total;
        }).catch((err:any) => {
          console.log(err);
        }).finally(()=>{
          loading.value = false;
        });

      }else{
        loading.value = true;
        productService.getProducts(currentPage.value, perPage.value).then((response)=>{
          state.data=response.data;
        }).catch((error)=>{
          console.log("error api : get products", error);
        })
        .finally(()=>{
          loading.value = false;
        });

      }

    
     
    }

    interface ValidationStatus {
      [key: string]: string;
    }

    interface ValidationHelp {
      [key: string]: string;
    }


    const validationStatus = ref<ValidationStatus>({});

    const validationHelp=ref<ValidationHelp>({});


    const getValidatingStatus=(id:any, fieldType: string)=>{
      return validationStatus.value[`${fieldType}_${id}`] || '';
    }

    const getValidatingHelp=(id:any, fieldType: string)=>{
      return validationHelp.value[`${fieldType}_${id}`] || '';
    }


    function isNumber(value:any) {
      return !isNaN(parseFloat(value)) && isFinite(value);
    }

    const clearError = (itemId: string, fieldType: string) => {
      console.log('clear error',itemId, fieldType );

      validationStatus.value[`${fieldType}_${itemId}`] = '';
      validationHelp.value[`${fieldType}_${itemId}`] = '';
    };
    interface SelectedOptions {
      [key: string]: any; 
    }

    const selectedOptions = reactive<SelectedOptions>({});

    const  handleUnitChange=(value:any, id:string) =>{
      console.log('Selected value:', value, 'id : ', id);
      selectedOptions[id] = value;

      validationStatus.value[`unit_${id}`] = 'success';
      validationHelp.value[`unit_${id}`] = '';
     
      
    }

    const addOrUpdateIngredient = (ingredientObject:any) => {
      const existingIndex = ingredientsPayload.findIndex(item => item.ingredient_id === ingredientObject.ingredient_id);
      if (existingIndex !== -1) {
        ingredientsPayload[existingIndex] = ingredientObject;
      } else {
        ingredientsPayload.push(ingredientObject);
      }
    };

    let ingredientsPayload: { ingredient_id: any; included_by_default: number; additional_cost: string; quantity_used: string; unit: any; }[] = [];
    let dynamicFieldsValidated=true;
    const validateDynamicFields=()=>{

      validationStatus.value = {};
      validationHelp.value = {};
      selectedOptions.value="";

      //validate dynamic fields:
      state.ingrAddArray.forEach((item) => {
        const ingredient_id = item.item_id;
        const included_by_default = item.default ? 1 : 0;

        const qtInput = document.getElementById(`qt_${item.item_id}`) as HTMLInputElement;
        if(qtInput.value ===""){
          validationStatus.value[`qt_${item.item_id}`] = 'error';
          validationHelp.value[`qt_${item.item_id}`] = t('Pos.RequiredField');
          dynamicFieldsValidated=false;
        }else if(!isNumber(qtInput.value)){
          validationStatus.value[`qt_${item.item_id}`] = 'error';
          validationHelp.value[`qt_${item.item_id}`] = t('Pos.numericField');
          dynamicFieldsValidated=false;
        }else{
          validationStatus.value[`qt_${item.item_id}`] = 'success';
          validationHelp.value[`qt_${item.item_id}`] = '';
          dynamicFieldsValidated=true;
        }


        const costInput = document.getElementById(`cost_${item.item_id}`) as HTMLInputElement;
        if(costInput.value ===""){
          validationStatus.value[`cost_${item.item_id}`] = 'error';
          validationHelp.value[`cost_${item.item_id}`] = t('Pos.RequiredField');
          dynamicFieldsValidated=false;
        }else if(!isNumber(costInput.value)){
          validationStatus.value[`cost_${item.item_id}`] = 'error';
          validationHelp.value[`cost_${item.item_id}`] = t('Pos.numericField');
          dynamicFieldsValidated=false;
        }else{
          validationStatus.value[`cost_${item.item_id}`] = 'success';
          validationHelp.value[`cost_${item.item_id}`] = '';
          dynamicFieldsValidated=true;
        }

        if (!selectedOptions[item.item_id] || selectedOptions[item.item_id] === "") {
          validationStatus.value[`unit_${item.item_id}`] = 'error';
          validationHelp.value[`unit_${item.item_id}`] = t('Pos.RequiredField');
          dynamicFieldsValidated=false;
        } else {
          validationStatus.value[`unit_${item.item_id}`] = 'success';
          validationHelp.value[`unit_${item.item_id}`] = '';
          dynamicFieldsValidated=true;
        }

        console.log('dynamicFieldsValidated', dynamicFieldsValidated)
       if(dynamicFieldsValidated===true){
        const ingredientObject = {
          ingredient_id:ingredient_id,
          included_by_default:included_by_default,
          additional_cost:costInput.value,
          quantity_used:qtInput.value,
          unit:selectedOptions[item.item_id],
        };

        //ingredientsPayload.push(ingredientObject);
        addOrUpdateIngredient(ingredientObject);
       }
        

        
        
          

      });

      
      console.log('ingr payload', JSON.stringify(ingredientsPayload) );
      
    }

    const checkDynamicFieldsValidity = () => {

      for (const key in validationStatus.value) {
        if (validationStatus.value[key] === 'error') {
          return false;
        }
      }
      return true;
    };


    const addProduct=()=>{
      state.ingrArray=[];
     
      addFormRef.value
      .validate()
      .then(() => {

        validateDynamicFields();

        const dynamicFieldsValid = checkDynamicFieldsValidity();

        if(dynamicFieldsValid){
          const hasIngr=addForm.has_ingredients ? 1 : 0;
          const refund=addForm.refundable ? 1 : 0;
          const stock=addForm.stockable ? 1 : 0; 
          const fixed_tax=addForm.fixed_tax?addForm.fixed_tax:"0";

          loading.value = true;

          productService.createProduct(addForm.name, addForm.description, addForm.price,
          addForm.purchase_price,addForm.category_id,addForm.manufacturer_id,
          addForm.supplier_id, hasIngr,addForm.vat_id,
          addForm.barcode,addForm.code_ean,toRaw(JSON.stringify(ingredientsPayload)),
          addForm.unit,addForm.display_rank,addForm.production_point_id,refund,fileInfo.value,fixed_tax,stock)
          .then((response)=>{

            addProd.value=false;
            //state.data.push(response.data);
            getProducts();
            showSuccessNotification(t('Pos.ProductAddSuccess'));
            
          }).catch((err) => {
          console.log(err)
          }).finally(() => {

            console.log('finish');
            loading.value = false;
            resetAdd();
            current.value=0;
            selectedIngredients.value=[];
            state.ingrAddArray=[];
            ingredientsPayload=[];
            fileList.value=[];

          }); 
        }else{
          console.log('dynamic fields not valid');
        }

      
        
         
       

      }).catch((error:any) => {
        console.error('Validation error:', error);
      });
    }

    const handleDelete = (record: any) => {
      Swal.fire({
        title: t('Pos.TitleDeleteProduct'),
        text: t('Pos.TextDeleteProduct'),
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: "#fc8019",
        confirmButtonText: t('Pos.Delete'),
        cancelButtonColor: "#d33",
        cancelButtonText: t('Pos.Cancel'),
        customClass: {
          popup: 'swal2-popup'
        }

      }).then((result) => {
        if (result.isConfirmed) {
          console.log("delete product");
          productService.deleteProduct(record.id).then((response)=>{
              state.data = state.data.filter(e => e.id !== record.id);
              showSuccessNotification(t('Pos.DeleteProductSuccess'));
          }).catch((error)=>{
            console.log("error api : delete products", error);
          });
        } else if (result.dismiss === Swal.DismissReason.cancel) {
          console.log("cancel delete ");
        }
      })
    };

    const handleProductionPoint=(selected:any)=>{
      console.log('selected pp ',selected);
      form.production_point_id=selected;
    }

    //update product

    const addOrUpdateIngredients = (ingredientObject:any) => {
      const existingIndex = ingredientsUpdatePayload.findIndex(item => item.ingredient_id === ingredientObject.ingredient_id);
      if (existingIndex !== -1) {
        ingredientsUpdatePayload[existingIndex] = ingredientObject;
      } else {
        ingredientsUpdatePayload.push(ingredientObject);
      }
    };

    let ingredientsUpdatePayload: { ingredient_id: any; included_by_default: number; additional_cost: string; quantity_used: string; unit: any; }[] = [];
    let dynamicUpdateValidated=true;
    const validateUpdatedFields=()=>{

      validationStatus.value = {};
      validationHelp.value = {};
      selectedOptions.value="";

      //validate dynamic fields:
      state.ingrArray.forEach((item) => {
        const ingredient_id = item.ingredient_id;
        const included_by_default = item.included_by_default ? 1 : 0;

        const qtInput = document.getElementById(`qtUpdate_${item.ingredient_id}`) as HTMLInputElement;
        if(qtInput.value ===""){
          validationStatus.value[`qtUpdate_${item.ingredient_id}`] = 'error';
          validationHelp.value[`qtUpdate_${item.ingredient_id}`] = t('Pos.RequiredField');
          dynamicUpdateValidated=false;
        }else if(!isNumber(qtInput.value)){
          validationStatus.value[`qtUpdate_${item.ingredient_id}`] = 'error';
          validationHelp.value[`qtUpdate_${item.ingredient_id}`] = t('Pos.numericField');
          dynamicUpdateValidated=false;
        }else{
          validationStatus.value[`qtUpdate_${item.ingredient_id}`] = 'success';
          validationHelp.value[`qtUpdate_${item.ingredient_id}`] = '';
          dynamicUpdateValidated=true;
        }


        const costInput = document.getElementById(`costUpdate_${item.ingredient_id}`) as HTMLInputElement;
        if(costInput.value ===""){
          validationStatus.value[`costUpdate_${item.ingredient_id}`] = 'error';
          validationHelp.value[`costUpdate_${item.ingredient_id}`] = t('Pos.RequiredField');
          dynamicUpdateValidated=false;
        }else if(!isNumber(costInput.value)){
          validationStatus.value[`costUpdate_${item.ingredient_id}`] = 'error';
          validationHelp.value[`costUpdate_${item.ingredient_id}`] = t('Pos.numericField');
          dynamicUpdateValidated=false;
        }else{
          validationStatus.value[`costUpdate_${item.ingredient_id}`] = 'success';
          validationHelp.value[`costUpdate_${item.ingredient_id}`] = '';
          dynamicUpdateValidated=true;
        }

        if (!selectedOptions[item.ingredient_id] || selectedOptions[item.ingredient_id] === "") {
          validationStatus.value[`unit_${item.ingredient_id}`] = 'error';
          validationHelp.value[`unit_${item.ingredient_id}`] = t('Pos.RequiredField');
          dynamicUpdateValidated=false;
        } else {
          validationStatus.value[`unit_${item.ingredient_id}`] = 'success';
          validationHelp.value[`unit_${item.ingredient_id}`] = '';
          dynamicUpdateValidated=true;
        }

        const included = document.getElementById(`defaultUpdate_${item.ingredient_id}`) as HTMLInputElement;


        if(dynamicUpdateValidated===true){
          const ingredientObject = {
            ingredient_id:ingredient_id,
            included_by_default:included_by_default,
            additional_cost:costInput.value,
            quantity_used:qtInput.value,
            unit:selectedOptions[item.ingredient_id],
          };

          addOrUpdateIngredients(ingredientObject);
        }

        
        

        
        
          

      });

      
      console.log('ingr payload', JSON.stringify(ingredientsUpdatePayload) );
      
    }

    const checkUpdateValidity = () => {

      for (const key in validationStatus.value) {
        if (validationStatus.value[key] === 'error') {
          return false;
        }
      }
      return true;
    };

    const onSubmit=(id:number)=>{

      
      formRef.value
      .validate()
      .then(()=>{

        validateUpdatedFields();
        const dynamicFieldsValid = checkUpdateValidity();
        if(dynamicFieldsValid){

          
          
          const hasIngr=form.has_ingredients ? 1 : 0;
          const refund=form.refundable ? 1 : 0;
          const stock=form.stockable ? 1 : 0
          const display_rank= form.display_rank?form.display_rank:'';

          console.log('form . fiex tax', form.fixed_tax);
          
          loading.value = true;
          productService.update(id.toString(),form.name, form.description, form.price,
            form.purchase_price,form.category_id,form.manufacturer_id,
            form.supplier_id, hasIngr,form.vat_id,
            form.barcode,form.code_ean,toRaw(JSON.stringify(ingredientsUpdatePayload)),
            form.unit,display_rank,form.production_point_id,refund,fileUpdateInfo.value, form.fixed_tax, stock).then((response)=>{
            state.data = state.data.map(el => el.id !== id ? el : response.data);
            showSuccessNotification(t('Pos.UpdateProductSuccess'));

            }).catch((err) => {
              console.log(err)
            }).finally(() => {
              console.log('finish');
              loading.value = false;
              open.value=false;
              currentUpdate.value=0;
              fileList.value=[];
              state.ingrArray=[];
              state.fileChanged=false;

              resetForm();

            }); 
            
          
        }else{
          console.log('dynamic fields not valid for update');
        }

      });

     

      

      // let payload = {
      //   name: form.name,
      //   description :form.description,
      //   purchase_price : form.purchase_price,
      //   category_id :form.category_id,
      //   manufacturer_id:form.manufacturer_id,
      //   supplier_id:form.supplier_id,
      //   has_ingredients:0,
      //   vat_id:form.vat_id,
      //   barcode:form.barcode,
      //   code_ean:form.code_ean,

      // }
      // console.log('payload', payload);
      // console.log('id', form.id);
   
      // formRef.value
      // .validate()
      // .then(() => {
      //   console.log('selected ingredients', selectedIngredients);
      //   productService.updateProduct(id, payload).then((response)=>{
      //     console.log('response', response),
      //     open.value=false;
      //     state.data = state.data.map(el => el.id !== id ? el : response.data);
      //     showSuccessNotification(t('Pos.UpdateProductSuccess'));
      //   }).catch((error)=>{
      //     console.log('error update product ', error);
      //   });
      // })
      // .catch((error: any) => {
      //   console.log('error', error);
      // });
     
    
    };

    interface ingredientInfo{
      ingredient_name:string,
      additional_cost:string,
      included_by_default:number,
      quantity_used:string,
      unit:string
    }

     interface Variant {
        id: string;
        name:string;
        qte:string;
      }
      const varinats_array = ref<Variant[]>([]); 
   
    const getProductById = async (id: string) => {
      loading.value=true;
      state.productHasIngr=0;
     
      const res =await productService.show(id)
      .then(res => {

        form.id=res.data.id;
        form.name = res.data.name;
        form.description = res.data.description;
        form.category_id=res.data.category_id;
        form.category_name=res.data.category.name;
        form.manufacturer_id=res.data.manufacturer_id;
        form.manufacturer_name=res.data.manufacturer.name;
        form.supplier_id=res.data.supplier_id;
        form.supplier_name=res.data.supplier.company_name;
        form.vat_id=res.data.vat_id;
        //form.price=(res.data.price-res.data.fixed_tax).toString();
        form.purchase_price = res.data.purchase_price;
        form.code_ean = res.data.code_ean;
        form.barcode = res.data.barcode;
        form.photo = res.data.photo;
        form.refundable=res.data.refundable === 1 ? true : false;
        form.stockable=res.data.is_stockable === 1 ? true : false;
        form.has_ingredients = res.data.has_ingredients === 1 ? true : false;
        form.has_ingr=res.data.has_ingredients;
        form.ingredients=res.data.ingredients;
        form.custom_fields=res.data.custom_fields ;
        form.customData= JSON.parse(res.data.custom_data);
        form.unit=res.data.unit,
        form.display_rank=res.data.display_rank,
        form.production_point_id=res.data.production_point_id? res.data.production_point_id :"";
        form.fixed_tax=res.data.fixed_tax?res.data.fixed_tax:"" ;

        form.price=res.data.unit_price;


        state.preferences=res.data.preferences?res.data.preferences:"";

        if(res.data.production_point!==null){
          form.production_point_name=res.data.production_point.name;
        }

        
       
        if ( form.has_ingr === 1 && res.data.ingredients) {
          const ingredientIds = [];
          const ingredientsInfo=[];
          
          for (const category in res.data.ingredients) {
            
            for (const ingredient of res.data.ingredients[category]) {
              ingredientIds.push(ingredient.ingredient_id);

              const ingredientInfo = {
                ingredient_id: ingredient.ingredient_id,
                //included_by_default: ingredient.included_by_default,
                included_by_default:ingredient.included_by_default === 1 ? true : false,
                additional_cost: ingredient.additional_cost,
                quantity_used: ingredient.quantity_used,
                unit: ingredient.unit,
               
                ingredient_name: ingredient.ingredient.name
              };
              selectedOptions[ingredient.ingredient_id] = ingredient.unit;
              ingredientsInfo.push(ingredientInfo);
            }
          }
          console.log('Ingredient IDs:', ingredientIds);
          form.ingredients_id=ingredientIds;
          form.ingredients_info=ingredientsInfo;
          state.ingrArray=ingredientsInfo;
          console.log('product  have ingr 3 steps');
         state.productHasIngr=1;
        }else{
          state.productHasIngr=0;
        }

        const variantsArray = res.data.variants_by_attribute_type
        ? res.data.variants_by_attribute_type.flatMap(function(item: { name: any; attribute_values: any[]; }) {
            return item.attribute_values.map(function(attributeValue) {
              return {
                attributeName:item.name,
                id: attributeValue.id,
                name: attributeValue.name,
                qte: "",
              };
            });
          })
        : [];
        varinats_array.value = variantsArray;

        /*
        if(res.data.variants_by_attribute_type!==null){
          varinats_array.value = res.data.ariants_by_attribute_type.map((item: any) => {
            return {
              id: item.id,
              name: item.name,
              qte: "",
            };
          });

        }
        */
         

        //loading.value=false;
          
        

       



 
        /*
        state.productHasIngr=res.data.has_ingredients;
        
        if (typeof res.data.ingredients === 'object') {
          const ingArray: { type: string; name: string; additional_cost: string; unit: string; quantity_used: string; included_by_default: number; }[] = [];
          const ingrIds: string[]=[];
          const ingrNames: string[]=[];
          // Iterate over the keys and values of the ingredients object
          Object.entries(res.data.ingredients).forEach(([ingredientType, ingredients]) => {
            // Assert ingredients as an array of objects
            if (Array.isArray(ingredients)) {
              ingredients.forEach((ingredient: any) => {
                ingArray.push({
                  type: ingredientType, 
                  name: ingredient.ingredient.name,
                  additional_cost: ingredient.additional_cost,
                  unit: ingredient.unit,
                  quantity_used: ingredient.quantity_used,
                  included_by_default: ingredient.included_by_default
                });
                ingrIds.push(ingredient.ingredient.id);
                ingrNames.push(ingredient.ingredient.name);
              });
            }
          });
          form.ingredients = ingArray;

          

          form.ingredients_id=ingrIds;
          form.ingredients_name=ingrNames;



          
        } else {
          console.error('Ingredients is not an object:', res.data.ingredients);
        }
        console.log('response has_ingredients', res.data.has_ingredients);
        console.log('form has_ingredients', form.has_ingr);
        */

        return res.data; 

      })
      .catch(error => {
        console.error('Error fetching product by id:', error);
      }).finally(()=>{
          loading.value=false;
      })
    }

    const getProductionPoints=()=>{
      productionPointService.getAll().then((response)=>{
        state.productionPoints=response.data;
      }).catch((error)=>{
        console.log('error get productionPoints api', error);
      });
    }

    



    const getCategories=()=>{
      categoryService.getAllWithNoPagination().then((response)=>{
        state.categories=response.data;
      }).catch((error)=>{
        console.log('error get categories api', error);
      });
    }

    const getManufacturers=()=>{
      manufacturerService.getAll().then((response)=>{
        state.manufacturers=response.data;
      }).catch((error)=>{
        console.log('error get manufacturers api', error);
      });
    }


    const getSuppliers=()=>{
      supplierService.getAll().then((response)=>{

        state.suppliers=response.data;
      }).catch((error)=>{
        console.log('error get suppliers api', error);
      });
    }


    const getVATs=()=>{
      vatrateService.getAll().then((response)=>{
        
        state.vats=response.data;
      }).catch((error)=>{
        console.log('error get vats api', error);
      });
    }

    const getCustomFields=()=>{
      customFieldsService.filter("product").then((response)=>{
        state.customFields= response.data;
       
      })
    }

    const getCategoryName=(id:any)=>{
      console.log('begin get category name');
      console.log('category id',id );
      const category = state.categories.find(category => category.id === id);
      return category ? category.name : null;
    }

    const getManufacturerName=(id:any)=>{
      const manufacturer = state.manufacturers.find(manufacturer => manufacturer.id === id);
      return manufacturer ? manufacturer.name : null;
    }
    const getSupplierName=(id:any)=>{
      const supplier = state.suppliers.find(supplier => supplier.id === id);
      return supplier ? supplier.company_name : null;
    }

    const getVATValue=(id:any)=>{
      const vat = state.vats.find(vat => vat.id === id);
      return vat ? vat.rate : null;
    }

    const getVariants=()=>{
      variantService.getAll().then((response)=>{
        
        state.varinats=response.data;
      }).catch((error)=>{
        console.log('error get vats api', error);
      });
    }

    const getAttributes =()=>{
      attributeService.getAll().then((response)=>{
        
        state.attributes=response.data;
      }).catch((error)=>{
        console.log('error get vats api', error);
      });
    }

    const getAttributeValues=(id:string)=>{
      attributeValuesService.getAll(id).then((res)=>{
        state.attributeValues=res.data;
      })
    }

   
    const updateModalWidth = () => {
      modalWidth.value = window.innerWidth <=1200 ? window.innerWidth : 720;
    };


    


    
    onMounted(() => {
      getProducts();
      getCategories();
      getManufacturers();
      getSuppliers();
      getAllIngredients();
      getVATs();

      btnDisabled();


      searchInput.value = document.querySelector('.ant-table-filter-dropdown input');

           
    });

    //dynamic add : 
    const selectedIngredientId = ref(null);

    //file 
    const fileList = ref([]);

    const beforeUpload = () => {
      return false;
    };

    const fileInfo = ref<UploadFile | null>(null);
    const fileUpdateInfo = ref<UploadFile | null>(null);


    let uploadedFile =ref(null);
    const handleChangeFile = (info: UploadChangeParam) => {
     fileInfo.value=info.file;
    };

    const handleUpdateChangeFile = (info: UploadChangeParam) => {
      state.fileChanged=true;
      fileUpdateInfo.value=info.file;
    };

  

    function handleDrop(e: DragEvent) {
      console.log(e);
    }

    const transformUpperCase=(str:string)=>{
      if (!str || str.length === 0) {
        return str;
      }

      return str.charAt(0).toUpperCase() + str.slice(1);
    }

    //collapse:
    const activeKey = ref(['1']);

    const customStyle ='background: none !important;border-radius: 4px;margin-bottom: 24px;border: 0;overflow: hidden';


   


    //multiselect

    const getAttributesValues=(id:string)=>{
      attributeValuesService.getAll(id)
        .then((res) => {

          console.log('reesss', res.data);
         
          const filteredData = res.data.filter((value:any) => value.name.trim() !== '');
          console.log('filtred data', filteredData);
          selectedItems.value.push({
            attributeId: id,
            attributeName:filteredData[0].attribute.name,
            values: filteredData, 
            selectedValue: null 
          });
        })
        .catch((error: any) => {
          console.error('Error fetching Attribute Values:', error);
        });
    }
    const selectedItems = ref<any[]>([]);

    const selectedAttributes = ref<any[]>([]);

    const value = ref([]);


    const handleChange = (val: string[]) => {
      console.log('selected items',selectedItems.value)
      console.log(`selected ${val}`);
      
      
      selectedAttributes.value=val;
      selectedAttributes.value.forEach((selectedItemId)=>{
        const existingAttribute = selectedItems.value.find(item => item.attributeId === selectedItemId);
        console.log('existing attribute', existingAttribute)
        if (!existingAttribute  ) {
          getAttributesValues(selectedItemId);
        }
      })

      const removedItems = selectedItems.value.filter(item => !selectedAttributes.value.includes(item.attributeId));
      removedItems.forEach(item => {
        const index = selectedItems.value.findIndex(selectedItem => selectedItem.attributeId === item.attributeId);
        selectedItems.value.splice(index, 1);
      });
      
    };

    const selectedAttribuetesValue = ref<any[]>([]);
 
    const handleChangeSelectChange=(value:[])=>{
      console.log('selected attributes', value);
      
      value.forEach((item)=>{
        const exists = selectedAttribuetesValue.value.find(el=>el==item);
        if(!exists){
          selectedAttribuetesValue.value.push(item);
        }
      })
      
     
      console.log('attributes', selectedAttribuetesValue.value);
    }



    const isBtnDisabled = ref<boolean>(true);

    const btnDisabled=()=>{
      if(selectedItems.value.length!==0){
        isBtnDisabled.value=true;
      }
    }

    //modal

    const openVariant = ref<boolean>(false);
    let prodocutId=ref();
    const addVariant = (record:any) => {
      getAttributes();
      prodocutId=record.id;
      state.productId=record.id;
      
      openVariant.value = true;
    };

    const resetVarinatForm = () => {
      formVarinat.attribute_id = '';
      formVarinat.quantity = '';
      formVarinat.name = '';
      formVarinat.code = '';
      formVarinat.code_ean = '';
      selectedAttribuetesValue.value=[];
      selectedAttributes.value=[];  
      value.value=[];
      selectedItems.value=[];
      formRefVarinat.value.resetFields();
      state.productId='';

      
    }



    const addProductVariant=()=>{

      formRefVarinat.value
      .validate()
      .then(()=>{
        let payload={
          product_id:state.productId,
          name:formVarinat.name,
          quantity:formVarinat.quantity,
          variant_attributes:JSON.stringify(selectedAttribuetesValue.value),
        }
        loading.value=true;
        productService.addProductVarinat(payload, state.productId)
        .then((res) => {
          console.log('res', res);
          loading.value=false;
          showSuccessNotification(t('Pos.ProductVariantAddSuccess'));


        }).catch((err) => {
          console.log(err)
        }).finally(() => {
          openVariant.value = false;
          resetVarinatForm();
        })
      }).catch((err:any) => {
        console.log('error validation', err);
      });
      

    }

    const handleCancelVariant = () => {
      openVariant.value = false;
      resetVarinatForm();
    };


    const handleVariantOk = (e: MouseEvent) => {
     addProductVariant();
      //openVariant.value = false;
    };

    const handleAttributeChange=(value:any)=>{
      console.log('value', value);
      console.log('form varinat ', formVarinat)

    }

    function extractFilename(url:any) {
      const parts = url.split('/');
      const filename = parts[parts.length - 1];
      return filename;
    }

    //img visible 
    const visible = ref<boolean>(false);
    const setVisible = (value:any): void => {
      visible.value = value;
    };

    const deletePhoto=()=>{
      form.photo="";
      state.fileChanged=true;
    }

    const previewVisible = ref();
    const togglePreview = (recordId:any) => {
      previewVisible.value = previewVisible.value === recordId ? null : recordId;
    }

    const formatPrice = (price:any) => {
      return parseFloat(price).toFixed(3); 
    };

    //preference modal  
    const openPref = ref<boolean>(false);

    const showPrefModal = (record:any) => {
      form.target_id=record.id;
      getProductById(record.id);
      openPref.value = true;
    }

    
    const resetPref=()=>{
      openPref.value = false;
    }

    //add pref dynamic fields 
    interface Preference {
      preference: string;
      description: string;
      target_type: string;
      target_id:string;
    }

    const formRefPref = ref<FormInstance>();
    const dynamicValidPrefForm = reactive<{ prefs: Preference[] }>({
      prefs: [],
    });

    const removePref = (item: Preference) => {
      const index = dynamicValidPrefForm.prefs.indexOf(item);
      console.log('remove', item);

      if (index !== -1) {
        dynamicValidPrefForm.prefs.splice(index, 1);
      }
    };

    const addPrefs = () => {
      dynamicValidPrefForm.prefs.push({
        preference: '',
        description: '',
        target_type:'product',
        target_id:form.target_id
      });
    };

    const onFinish = (values:any) => {
      console.log('Received values of form:', values);
      console.log('dynamicValidPrefForm.prefs:', dynamicValidPrefForm.prefs);


      dynamicValidPrefForm.prefs.forEach((item)=>{
        console.log('item of dynamic ', item);
        loading.value = true;
        preferenceService.store(item).then((res)=>{
          console.log('res', res);
          showSuccessNotification(t('Pos.PreferenceAddSuccess'));
          state.preferences.push(res.data);
        }).catch((error)=>{
          console.log(error);
        }).finally(()=>{
          loading.value = false;
        });
      });
      dynamicValidPrefForm.prefs = [];
    };

    const showDeletePref = (id: any) => {
      Swal.fire({
        title: t('Pos.TitleDeletePreference'),
        text: t('Pos.TextDeletePreference'),
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: "#fc8019",
        confirmButtonText: t('Pos.Delete'),
        cancelButtonColor: "#d33",
        cancelButtonText: t('Pos.Cancel'),
        customClass: {
            popup: 'swal2-popup'
        }

      }).then((result) => {
        if (result.isConfirmed) {
          deletePref(id);
        } else if (result.dismiss === Swal.DismissReason.cancel) {
          console.log("cancel delete ");
        }
      })
    };

    const deletePref=(id:any)=>{
      loading.value = true;
      preferenceService.delete(id).then((res)=>{
        console.log('delete success', res);
        showSuccessNotification(t('Pos.PreferenceDeleteSuccess'));
        state.preferences = state.preferences.filter(e => e.id !== id);
      }).catch((err) => {
        console.log(err);
      }).finally(() => {
        loading.value = false;
      })
    }

    const updatePreference=(item:any)=>{
      let hasErrors = false;
      const txtInput = document.getElementById(`name${item.id}`) as HTMLInputElement;
      const descInput = document.getElementById(`desc${item.id}`) as HTMLInputElement;

      if(txtInput.value ===""){
        validationStatus.value[`name${item.id}`] = 'error';
        validationHelp.value[`name${item.id}`] = t('Pos.RequiredField');
        hasErrors = true;
      }else{
        validationStatus.value[`name${item.id}`] = 'success';
        validationHelp.value[`name${item.id}`] = '';  
      }

      if (!hasErrors){

        let payload={
          id:item.id,
          target_type:"product",
          target_id:item.target_id,
          preference:txtInput.value,
          description:descInput.value,
        }

        console.log('payload',payload);
        loading.value=true;
        preferenceService.update(item.id,payload).then((res)=>{
          console.log('pref updated success', res);
          showSuccessNotification(t('Pos.PreferenceUpdateSuccess'));
          loading.value=false;
        }).catch((error)=>{
          console.log('error updating prefrene', error);
          loading.value=false;
        }).finally(()=>{
          
          validationHelp.value={};
          validationStatus.value={};
        });

      }else{
        console.log('error in validation of dynmaic fields');
      }
    }

    //rtl
    const { locale } = useI18n();
    const textDirection = computed(() => {
      return locale.value === 'ar' ? 'rtl' :'ltr';}
    );

    //pack drawer 
    const packFromRef = ref();

    
    const packForm = reactive({
      name: '',
      description: '',
      purchase_price: '',
      price: '',
      category_id: '',
      manufacturer_id: '',
      supplier_id: '',
      vat_id: '',
      code_ean: '',
      barcode: '',
      refundable:false,
      display_rank:'',
      products:'',
      unit:'',
    });

    const openPack = ref<boolean>(false);

    const showPackDrawer = (record: any) => {
      updateModalWidth();
      openPack.value=true;
    }

    const packRules: Record<string, Rule[]> = {
      name: [{ required: true, message:  t('Pos.RequiredField') }],
      category_id: [{ required: true, message:  t('Pos.RequiredField') }],
      manufacturer_id: [{ required: true, message: t('Pos.RequiredField') }],
      price: [
        { required: true, message:  t('Pos.RequiredField') },
        { 
          validator: (_, value) => {
            if (!isNaN(value)) {
              return Promise.resolve();
            }
            return Promise.reject( t('Pos.numericField'));
          }
        }
      ],
      purchase_price: [
        { required: true, message:  t('Pos.RequiredField') },
        {
          validator: (_, value) => {
            if (!isNaN(value)) {
              return Promise.resolve();
            }
            return Promise.reject(t('Pos.numericField'));
          }
        }
      ],
      display_rank: [
       
        {
          validator: (_, value) => {
            if (!isNaN(value)) {
              return Promise.resolve();
            }
            return Promise.reject(t('Pos.numericField'));
          }
        }
      ],
      supplier_id: [{ required: true, message: t('Pos.RequiredField') }],
      code_ean :[{ required: true, message: t('Pos.RequiredField') }],
      barcode :[{ required: true, message: t('Pos.RequiredField') }],
      vat_id :[{ required: true, message: t('Pos.RequiredField') }],
      unit :[{ required: true, message: t('Pos.RequiredField') }],
    };

    const resetAddPack=()=>{

      packFromRef.value.resetFields();

    }

    //pack stepper
    const curentPack = ref<number>(0);

    const nextPack = () => {

      packFromRef.value.validate().then(()=>{
       
        curentPack.value++;

      }).catch((error:any) => {
        console.error('Validation error:', error);
      });
    };

    const prevPack = () => {
      curentPack.value--;
    };

    const stepsPack = [
      {
        title: t('Pos.Step1'),
        content: 'first-content-pack',
      },
      {
        title: t('Pos.Step2'),
        content: 'second-content-pack',
      }
    ];

    const itemsPack = stepsPack.map(item => ({ key: item.title, title: item.title }));

   
    function isNum(value:any) {
      return !isNaN(parseFloat(value)) && isFinite(value);
    }

    const addPack=()=>{
      packFromRef.value.validate().then(()=>{
        let hasErrors = false;
        let products: { productId: any; quantity: any; hasChoice: any; choices: any; }[]=[];
        state.productsPack.forEach((item)=>{

          if(item.quantity ===""){
            validationStatus.value[`qt_${item.key}`] = 'error';
            validationHelp.value[`qt_${item.key}`] = t('Pos.RequiredField');
            hasErrors = true;

          }else if(!isNum(item.quantity)){
          
            validationStatus.value[`qt_${item.key}`] = 'error';
            validationHelp.value[`qt_${item.key}`] = t('Pos.numericField');
            hasErrors = true;

          }else{
            validationStatus.value[`qt_${item.key}`] = '';
            validationHelp.value[`qt_${item.key}`] = '';

          }

          if(item.choice === true && item.option.selectedChoices.length === 0){
            console.log('heree');
            validationStatus.value[`choices_${item.key}`] = 'error';
            validationHelp.value[`choices_${item.key}`] = t('Pos.RequiredField');
            hasErrors = true;

          }else{
            validationStatus.value[`choices_${item.key}`] = '';
            validationHelp.value[`choices_${item.key}`] = '';

          }


          if(!hasErrors){

            products.push({
              productId:item.key,
              quantity:item.quantity,
              hasChoice:item.choice,
              choices:item.selectedChoices,
            });

          }
          


        });

        console.log('products', products);
        
      }).catch(()=>{
        console.log('error in validations');
      })
    }

    //file pack
    const fileListPack = ref([]);

    const beforeUploadPack = () => {
      return false;
    };

    const fileInfoPack = ref<UploadFile | null>(null);
    const fileUpdateInfoPack = ref<UploadFile | null>(null);


    let uploadedFilePack =ref(null);
    const handleChangeFilePack = (info: UploadChangeParam) => {
     fileInfoPack.value=info.file;
    };

    const handlePackUpdateChangeFile = (info: UploadChangeParam) => {
      state.fileChanged=true;
      fileUpdateInfo.value=info.file;
    };

    function handleDropPack(e: DragEvent) {
      console.log(e);
    }


    //multi selecti products with search
    let debounceTimer: ReturnType<typeof setTimeout> | null = null;

    const fetchProduct = (value: any) => {

            
      //disable fetching when the input is empty
      if(value===''){
          return;
      }


      state.products = [];
           
      state.fetching = true;


      //clear previous timer
      if (debounceTimer) {
        clearTimeout(debounceTimer);
      }


      //fetch products 

      debounceTimer = setTimeout(() => {
        productService.getProductsByName(value)
        .then((res) => {
          const data = res.data.map((product: { name: any; id: any; }) => ({
            label: `${product.name}`,
            value: product.id,
            quantity:'',
            choice:false,
            choices:[],
            selectedChoices:[],
            fetchingChoices: false,
          }));

              
          state.products = data;
          state.fetching = false;


          console.log('products',state.products);
          console.log('state.value : ', state.value);


        })
        .catch((error) => {
          console.log('error fetching products', error);
        });
      }, 700);

    };
     
    watch(state.value, () => {
      console.log('watcher state.value', state.value);
      state.products = [];
      state.fetching = false;
    });

    const handleProductsSelect=(value: any[])=>{
      console.log('selected product', value);
      
      const existingProducts = state.productsPack;
      value.forEach(selectedProduct => {
        const existingProduct = existingProducts.find(product => product.value === selectedProduct.value);
        if (!existingProduct) {
          existingProducts.push({ ...selectedProduct, quantity: '' });
        }
      });

      state.productsPack = existingProducts.filter(product =>
        value.some(selectedProduct => selectedProduct.value === product.value)
      );

      console.log('state.productsPack', state.productsPack);
    }

    // multi select for choice 
    let debounceCoicesTimer: ReturnType<typeof setTimeout> | null = null;
    const fetchChoices  = (item:any,value:any) => {

      console.log('start fetching', item, 'value',value)
      //disable fetching when the input is empty
      if(value===''){
        return;
      }


      item.choices = [];
            
      item.fetchingChoices = true;


      //clear previous timer
      if (debounceCoicesTimer) {
          clearTimeout(debounceCoicesTimer);
      }


      //fetch choices 

      debounceCoicesTimer = setTimeout(() => {
        productService.getProductsByName(value)
        .then((res) => {
          const data = res.data.map((product: { name: any;  id: any; }) => ({
            label: `${product.name}`,
            value: product.id,
          }));

                
          item.choices = data;  
          item.fetchingChoices=false;
         
          
                

          console.log('item.valueChoices : ', item.selectedChoices);


        })
        .catch((error) => {
          console.log('error fetching users', error);
        });
      }, 700);

     

    };
    
    const handleChoiceSelect =(item:any,selectedChoices:any[]) =>{
      console.log('selected product choice', selectedChoices, 'for item', item);
      item.selectedChoices = selectedChoices;

      console.log('final item', item);
    }

    const selectPlacement=ref('bottom')
    const updateSelectPlacement=()=>{
      if (window.innerWidth < 768) { 
        selectPlacement.value = 'topLeft';
      } else {
        selectPlacement.value = 'bottomLeft';
      }
    }

   

    
   
        


        




   

    
    
    
    return {
      updateModalWidth,
      modalWidth,
      t,
      locale,
      textDirection,
      openPref,
      showPrefModal,
      dynamicValidPrefForm,
      onFinish,
      addPrefs,
      showDeletePref,
      updatePreference,
      formRefPref,
      removePref,
      resetPref,
      formatPrice,
      visible,
      setVisible,
      previewVisible,
      togglePreview,
      deletePhoto,
      extractFilename,
      selectedItems,
      isBtnDisabled,
      handleChangeSelectChange,
      handleChange,
      value,
      openVariant,
      addVariant,
      handleVariantOk,
      selectedIngredientId,
      loaderVisible,
      columns,
      data,
      steps,
      current,
      next,
      prev,
      items,
      handleDelete,
      searchInput,
      open,
      afterOpenChange,
      showDrawer,
      state,
      handleSearch,
      handleReset,
      form,
      formRef,
      rules,
      onSubmit,
      resetForm,
      handleIngredientChange,
      openAdd,
      afterOpenAddChange,
      showOpenDrawer,
      addProduct,
      resetAdd,
      addForm,
      selectedAddIngredients,
      addIngredient,
      handleAddChange,
      addFormRef,
      navigatePage,
      addProd,
      afterAddChange,
      showAddDrawer,
      handleIngredientsChange,
      addNewIngredient,
      addNew,
      refFrom,
      dynamicValidateForm,
      removeIngredient,
      fileList,
      beforeUpload,
      handleChangeFile,
      handleDrop,
      loading,
      currentPage,
      handlePageChange,
      perPage,
      goNext,
      handleHasIngredients,
      hasIngredients,
      passedToStep3,
      selectedIngredients,
      addP,
      selectRef,
      openDisplay,
      showDisplayDrawer,
      activeKey,
      customStyle,
      getCategoryName,
      getManufacturerName,
      getSupplierName,
      getVATValue,
      currentUpdate,
      goNextUpdate,
      nextUpdate,
      prevUpdate,
      stepsUpdate,
      itemsUpdate,
      onUpdateDrawerClose,
      transformUpperCase,
      selectedVatinats,
      handleVarinatsChange,
      addInput,
      formRefVarinat,
      formVarinat,
      handleAttributeChange,
      optionsUpdate,
      updatedIngredients,
      handleMultiChangeUpdate,
      fileUpdateInfo,
      handleUpdateChangeFile, 
      handleUpdateIngredient,
      stepperUpdateRef,
      afterOpenChangeUpdate ,
      getValidatingStatus,
      getValidatingHelp,
      clearError,
      handleUnitChange,
      handleCancelVariant,
      resetVarinatForm,
      handleProductionPoint,
      onShowSizeChange,
      selectPagination,
      handleSelectPaginationChange,
      simpleImage,
      variantRules,
      varinats_array,
      packFromRef,
      packForm,
      openPack,
      showPackDrawer,
      packRules,
      resetAddPack,
      curentPack,
      nextPack,
      prevPack,
      stepsPack,
      itemsPack,
      addPack,
      handleDropPack,
      handlePackUpdateChangeFile,
      uploadedFilePack,
      handleChangeFilePack,
      fileUpdateInfoPack,
      fileListPack,
      beforeUploadPack,
      fetchProduct,
      handleProductsSelect,
      fetchChoices,
      handleChoiceSelect,
      selectPlacement








      






    };
  }


})
</script>

<style scoped>
/*form item */

.input-form {
  font-family: "Exo 2", sans-serif;
}

.label-form {
  font-family: "Exo 2", sans-serif;
  font-size: 14px;
  font-weight: 600;
}

/*form item */


/* switch container */
.switch-container {
  display: flex;
  align-items: center;
}

.switch-container p {
  font-family: "Exo 2", sans-serif;
  margin-right: 16px;
  font-size: 14px;
  font-weight: 600;
}

/* switch container */

.btn-actions {
  display: flex;
  justify-content: center;
}

.eye-icon {
  font-size: 24px;
  padding: 0;
}


/* drawer */
.btn-save {
  color: #1b6a2b;
  border: 2px solid #1b6a2b;
  box-shadow: inset 0 0 0 0 #1b6a2b;
}

.btn-save:hover {
  color: white;
  box-shadow: inset 0 -100px 0 0 #1b6a2b;
}

.btn-save:active {
  transform: scale(0.9);
}

.btn-cancel {
  color: #ff4d4f;
  border: 2px solid #ff4d4f;
  box-shadow: inset 0 0 0 0 #ff4d4f;
}

.btn-cancel:hover {
  color: white;
  box-shadow: inset 0 -100px 0 0 #ff4d4f;
}

.btn-cancel:active {
  transform: scale(0.9);
}


.drawer-btn {
  position: relative;
  display: inline-block;
  margin: 15px;
  padding: 7px 10px;
  text-align: center;
  font-size: 15px;
  letter-spacing: 1px;
  text-decoration: none;
  background: transparent;
  cursor: pointer;
  transition: ease-out 0.5s;
  border-radius: 10px;
}


/* drawer */

.action-btn {
  font-family: inherit;
  font-size: 14px;
  color: white;
  display: flex;
  padding: 0;
  padding-right: 5px;
  align-items: center;
  border: none;
  border-radius: 8px;
  overflow: hidden;
  transition: all 0.2s;
  cursor: pointer;
  margin-right: 5px;

}

.action-btn span {
  display: block;
  margin-left: 0.3em;
  transition: all 0.3s ease-in-out;
}

.action-btn .eye-icon {
  display: block;
  transform-origin: center center;
  transition: transform 0.3s ease-in-out;
  transform: scale(0.7);
}

.action-btn:hover .svg-wrapper {
  animation: fly-1 0.6s ease-in-out infinite alternate;
}

.action-btn:hover .eye-icon {
  transform: translateX(1em) rotate(45deg) scale(0.8);
}

.action-btn:hover span {
  transform: translateX(3em);
}

.action-btn:active {
  transform: scale(0.95);
}

.drawer-footer {
  display: flex;
  margin-left: -15px;
  margin-top: 25px;
}

@keyframes fly-1 {
  from {
    transform: translateY(0.1em);
  }

  to {
    transform: translateY(-0.1em);
  }
}


/* btn add container */

.btn-container {
  display: flex;
  margin-bottom: 20px;
  justify-content: flex-end;
}

/* swal */
.swal2-popup {
  font-family: 'Exo 2', sans-serif;
}

/* loader container */
.loader-container {
  display: flex;
  align-items: center;
  justify-content: center;
}

.breadcrumb-icon{
  cursor: pointer;
}

/* stepper */

.steps-content {
  margin-top: -45px;
  /*min-height: 200px;*/
  /*new */
  /*height: calc(100% - 16px);*/
  text-align: center;
  padding-top: 80px;
}

.steps-action {
  margin-top: 24px;
}

[data-theme='dark'] .steps-content {
  background-color: #2f2f2f;
  border: 1px dashed #404040;
}

.dynamic-item{
  display: flex;
  margin-left: 15px;
  justify-content: flex-start
}

.add-ingredient-btn{
  display: flex;
  justify-content: center;
  align-items: flex-end;
}

.dynamic-items{
  display: flex;
  align-items: center;
  margin-bottom: 8px;
  flex-direction: row;
  gap:8px 
}

.items{
  display:flex;
  justify-content:flex-end;
  flex-direction: row;
  align-items:flex-start;
  margin-left:14px;
  gap : 8px;
}

.remove-ing{
  cursor: pointer;
  position: relative;
  top: 4px;
  font-size: 24px;
  color: #999;
  transition: all 0.3s;
}

.pagination{
  margin-top: 10px;
  display: flex;
  justify-content: flex-end;
  flex-direction: column;
}

.select-input {
  width: 100%;
}
.dynamic-input{
  width: 100%;
}

.ingredient-name{
  font-size: 14px;
  font-weight: 600;
}

.view-btn{
  color: #fc8019;
  border-color: #fc8019;
  background: #fafafa;
}

  /* product info  */
  .product-info-container{
    margin-left:10px
  }
  .info{
    margin-bottom: 10px;
  }
  .info-title{
    font-size: 15px !important;
    font-weight: bold;
  }
  .info-txt{
    font-size: 14px;
    max-width: 400px;
  }

  .collapse{
    background: none !important;
    
    font-weight: 500;
  }

  .custom-descriptions {
    display: flex;
    flex-direction: column;
  }

  .full-width-label {
    width: 100%;
  }

  .desc-label{
    font-weight: 500;
    font-size: 14px;
  }

  .description-item{
    padding: 0 !important;
  }

  .photo-container{
    margin-bottom: 12px;
    margin-top: 12px;
  }

  .info-title{
    font-size: 14px;
    font-weight: 600;
    padding-right: 15px;
  }

  .ingredient-des{
    margin-bottom: 15px;
  }

  .custom-header{
    font-size: 16px; 
    font-weight: 600;
    line-height: 1.5; 
  }

  .ant-collapse-expand-icon{
    height: 25px !important;
  }

  .variant-btn{
    color: #3b8dffdf;
    border-color: #3b8dffdf;
    background: #fafafa;
  }

  .variant-btn:hover{
    color: #3b8dffdf;
    border-color: #3b8dffdf;
    background: #fafafa;
  }
  .add-btn:hover{
    color: #fff;
    box-shadow: inset 0 -100px 0 0 #fc8019;
    border-color: #fc8019;
  }

  .photo-info{
  padding:0px 8px;
  display: flex;
  justify-content: space-between;
  cursor: pointer;
  }

  .photo-info:hover{
    background: #efefefec;
    
  }

  .image-container {
    max-width: 40px;  
  }

  .image-container img{
    width: 100%;
    height: auto;
  }

  .check-btn{
    display: flex;
    flex-direction: row;
    
  }

  .swicth-btn{
    margin-left: 20px;
  }

  .ingredients-container{
    margin-top: 20px;
  }

  .img-table{
    max-width: 40px;
    max-height: 40px;
  }

  .pref-btn{
    color: #ff00268a;
    border-color: #ff00268a;
    background: #fafafa;
  }


  .cancel-pref-icon{
    cursor: pointer;
    color:#ea5455 ;
    font-size: 20px;
  }

  .edit-pref-icon{
    cursor: pointer;
    color:#fc8019 ;
    font-size: 20px;
  }

  .ingr-name-container{
    display: flex;
    justify-content: flex-start;
    margin-bottom: 5px;
  }

  .ingr-content-container{
    margin-bottom: 5px;
  }

  /* CSS for RTL */
  .is-rtl {
    direction:rtl;
  }
  .paginationArrows{
    direction: ltr;
    margin-bottom: 16px;

  }

  .table-container {
    overflow-x: auto;
  }
  .action-btn-container{ 
    margin-bottom: 10px;
    display: flex;
    justify-content: flex-end;
  }

 
       
  /*meduim*/
  @media (min-width: 768px) {

    .pagination {
      display: flex;
      flex-direction: column;
      align-items: flex-end;
    }

    .select-input {
      width: 40%;
    }

  
  }

  /*large*/
  @media (min-width: 1024px) {

    .pagination {
      display: flex;
      flex-direction: row;
      align-items: flex-start;
      justify-content: flex-end;
    }

    .select-input {
      width: 20%;
    }
          

  }

</style>