<template>
    
    <div>
        <LbrxLoaderSpinner :is-loaded="!loading" />
    </div>

    <div>
        <a-breadcrumb style="margin: 16px 0">
            <a-breadcrumb-item @click="navigatePage('/')" class="breadcrumb-icon">
                {{t('Pos.Home')}}
            </a-breadcrumb-item>
            <a-breadcrumb-item>{{t('Pos.Workers')}}</a-breadcrumb-item>
        </a-breadcrumb>
    </div>

    <div class="btn-container">
        <a-button class="add-btn"  @click="showAddDrawer">{{ t('Pos.Add') }}</a-button>
    </div>

    <div>
        <a-table
            :columns="columns"
            :data-source="state.data"
            :pagination="false"
            class="table-container"
        >

            <template #bodyCell="{ column, record }">
                <template v-if="column.key=='actions'">
                    <a-space>
                        <a-tooltip>
                            <template #title>{{ t('Pos.View') }}</template>
                            <a-button class="view-btn" @click="() => showDisplayDrawer(record)">
                            <template #icon>
                                <EyeOutlined/>
                            </template>
                            </a-button>
                        </a-tooltip>

                        <a-tooltip>
                            <template #title>{{ t('Pos.Edit') }}</template>
                            <a-button type="primary"  @click="() => showDrawer(record)">
                            <template #icon>
                                <EditOutlined />
                            </template>
                            </a-button>
                        </a-tooltip>
                        
                        <a-tooltip>
                            <template #title>{{ t('Pos.Delete') }}</template>
                            <a-button type="primary" danger @click="showDeletePopup(record)">
                            <template #icon>
                                <DeleteOutlined />
                            </template>
                            </a-button>
                        </a-tooltip>


                    </a-space>
                </template>
            
                <template v-if="column.key === 'created_at'">
                    {{ formatDisplayDate(record[column.key]) }}
                </template>

                 <template v-if="column.key==='is_active'">
                    <template v-if="record.is_active===1 || record.is_active===true">
                        <check-circle-two-tone two-tone-color="#52c41a" />
                    </template>
                    <template  v-if="record.is_active===0 || record.is_active===false">
                        <close-circle-two-tone two-tone-color="#ff4d4f"/>
                    </template>
                </template>

            </template>

            <template #customFilterDropdown="{ setSelectedKeys, selectedKeys, confirm, clearFilters, column }">
                <div style="padding: 8px">
                <a-input
                    ref="searchInput"
                    :placeholder="`${column.title}`"
                    :value="selectedKeys[0]"
                    style="width: 188px; margin-bottom: 8px; display: block"
                    @change="e => setSelectedKeys(e.target.value ? [e.target.value] : [])"
                    @pressEnter="handleSearch(selectedKeys, confirm, column.dataIndex)"
                /> 

                <a-button
                    type="primary"
                    size="small"
                    style="width: 90px; margin-right: 8px"
                    @click="handleSearch(selectedKeys, confirm, column.dataIndex)"
                >
                
                    {{ t('Pos.Search') }}
                </a-button>
                <a-button size="small" style="width: 90px" @click="handleReset(clearFilters)">
                    {{ t('Pos.Reset') }}
                </a-button>
                </div>
            </template>

            <template #customFilterIcon="{ filtered }">
                <search-outlined :style="{ color: filtered ? '#108ee9' : undefined }" />
            </template>
        </a-table>
       
        <div class="pagination" v-if="state.totalPagesSearch ===0 && !state.beginSearch">
            <a-pagination class="paginationArrows" v-model:current="currentPage" :total="state.totalPages" @change="handlePageChange"  :default-page-size="perPage"/>
        </div>

        <div class="pagination" v-if="state.totalPagesSearch !==0 && state.beginSearch">
        
            <a-pagination class="paginationArrows" model:current="currentPageSearch" :total="state.totalPagesSearch" @change="handlePageChangeSearch"  :default-page-size="perPageSearch"/>
        </div>

        <!-- display drawer -->
            <a-drawer
                v-model:open="openDisplay"
                class="custom-class"
                root-class-name="root-class-name"
                :title="t('Pos.WorkerInfo')"
                placement="right"
                :width="modalWidth"
                @after-open-change="afterOpenChange"
            >

                <a-form ref="formRef" :model="form" :rules="rules" layout="vertical">

                    <a-row :gutter="16">   
                        <a-col :span="24">
                            <p><span class="info-title">{{ t('Pos.Username') }} :</span>{{form.username}}</p>
                        </a-col>
                    </a-row>

                    <a-row :gutter="16">   
                        <a-col :span="12">
                            <p><span class="info-title">{{ t('Pos.FisrtName') }} :</span>{{form.first_name}}</p>
                        </a-col>

                        <a-col :span="12">
                            <p><span class="info-title">{{ t('Pos.LastName') }} :</span>{{form.last_name}}</p>
                        </a-col>   
                    </a-row>    

                    <a-row :gutter="16">
                        <a-col :span="12">
                            <p><span class="info-title">{{ t('Pos.Email') }} :</span>{{form.email}}</p>
                        </a-col>
                        <a-col :span="12">
                            <p><span class="info-title">{{ t('Pos.Badge') }} :</span>{{form.badge_id}}</p>
                        </a-col>
                       
                    </a-row>

                    <a-row :gutter="16">
                        <a-col :span="12">
                            <p>
                                <span class="info-title">{{ t('Pos.Active') }}</span>
                                <template v-if="form.is_active===1 || form.is_active===true">
                                    <check-circle-two-tone two-tone-color="#52c41a" />
                                </template>
                                <template  v-if="form.is_active===0 || form.is_active===false">
                                    <close-circle-two-tone two-tone-color="#ff4d4f"/>
                                </template>

                            </p>
                                    
                        </a-col>
                        <a-col :span="12">
                            <p><span class="info-title">{{ t('Pos.Pin') }} :</span>{{form.pin}}</p>
                        </a-col>
                    </a-row>

                    <!-- taraji -->


                    <!-- <a-row>
                        <a-col :span="24">
                            <a-tooltip>
                                <template #title>{{t('Pos.discountWorkerTxt')}}</template>
                                <QuestionCircleOutlined class="tooltip-icon"/>
                            </a-tooltip>
                            <span class="info-title">{{ t('Pos.DiscountValue') }}</span>
                        </a-col>
                    </a-row> -->

                    <!-- <a-row :gutter="16" class="discount-value-container">

                       
                        <a-col :span="18">
                            <a-progress
                            :stroke-color="{
                                from: '#fc4519',
                                to: '#fc8019',
                            }"
                            :percent="60"
                            status="active"
                            :showInfo="false"
                            />
                        </a-col>
                        <a-col :span="6" >
                            <p class="discount-amount-txt">60/100 DT</p>
                        </a-col>
                       

                    
                    </a-row>
                 -->
                    <a-row :gutter="16">

                        <a-col :span="24">
                            <p><span class="info-title">{{ t('Pos.CreatedAt') }} :</span>{{formatStringDate(form.created_at)}}</p>
                        </a-col>

                    </a-row>


                </a-form>
        

            </a-drawer>
        <!-- display drawer -->

        <!-- update drawer -->
            <a-drawer
                v-model:open="open"
                class="custom-class"
                root-class-name="root-class-name"
                :title="t('Pos.UpdateWorker')"
                placement="right"
                :width="modalWidth"
                @after-open-change="afterOpenChange"
            >

                <a-form ref="formRef" :model="form" :rules="rules" layout="vertical">

                    <a-row :gutter="16">   
                    
                        <a-col :span="24">
                            <a-form-item  name="username">
                                <template #label>
                                <span class="label-form">{{ t('Pos.Username') }}</span>
                                </template>
                                <a-input  v-model:value="form.username" :placeholder="t('Pos.Username')" class="input-form" />
                            </a-form-item>
                        </a-col>

                    </a-row>

                    <a-row :gutter="16">   
                        <a-col :span="12">
                            <a-form-item  has-feedback name="first_name">
                                <template #label>
                                <span class="label-form">{{ t('Pos.FisrtName') }}</span>
                                </template>
                                <a-input  v-model:value="form.first_name" :placeholder="t('Pos.FisrtName')" class="input-form" />
                            </a-form-item>
                        </a-col>

                        <a-col :span="12">
                            <a-form-item has-feedback name="last_name">
                                <template #label>
                                <span class="label-form">{{ t('Pos.LastName') }}</span>
                                </template>
                                <a-input  v-model:value="form.last_name" :placeholder="t('Pos.LastName')" class="input-form" />
                            </a-form-item>
                        </a-col>   
                    </a-row>


                    <a-row :gutter="16">
                        <a-col :span="12">
                            <a-form-item  name="email">
                                <template #label>
                                <span class="label-form">{{ t('Pos.Email') }}</span>
                                </template>
                                <a-input  v-model:value="form.email" :placeholder="t('Pos.Email')" class="input-form" />
                            </a-form-item>
                        </a-col>

                        <a-col :span="12">
                            <a-form-item  name="pin">
                                <template #label>
                                    <span class="label-form">{{ t('Pos.Pin') }}</span>
                                </template>
                                <a-input  v-model:value="form.pin" :placeholder="t('Pos.Pin')" class="input-form" />
                            </a-form-item>
                        </a-col>
                      
                       
                    </a-row>

                    <!-- taraji -->
                    <!-- <a-row :gutter="16">   
        
                        <a-col :span="24">
                            <a-form-item has-feedback name="discountValue">
                                <template #label>
                                    <a-tooltip>
                                        <template #title>{{t('Pos.discountWorkerTxt')}}</template>
                                        <QuestionCircleOutlined class="tooltip-icon"/>
                                    </a-tooltip>
                                    <span class="label-form">{{ t('Pos.DiscountValue') }}</span>
                                </template>
                                <a-input  v-model:value="addForm.discountValue" :placeholder=" t('Pos.DiscountValue')" class="input-form" />
                            </a-form-item>
                        </a-col>   
                    </a-row> -->

                





                    <a-row :gutter="16">
                        <a-col :span="12">
                            <a-form-item  name="created_at">
                                <template #label>
                                    <span class="label-form">{{ t('Pos.CreatedAt') }}</span>
                                </template>
                                <a-date-picker show-time :placeholder="t('Pos.CreatedAt')" class="date-picker"  :value="formatDate(form.created_at)" disabled>
                                </a-date-picker>
                            </a-form-item>
                        </a-col>

                        <a-col :span="12">
                            <a-form-item  name="is_active">
                                <template #label>
                                    <span class="label-form">{{ t('Pos.Active') }}</span>
                                </template>
                                <a-switch v-model:checked="form.is_active" />
                            </a-form-item>
                        </a-col>
                    </a-row>

                </a-form>
                <template #footer>
                    <a-space>
                        <a-button  type="primary" @click="updateUser(form.id)">{{ t('Pos.Update') }}</a-button>
                        <a-button type="primary" danger @click="cancelUpdate">{{ t('Pos.Cancel') }}</a-button>
                    </a-space>
                </template>

            </a-drawer>
        <!-- update drawer -->

        <!-- add drawer -->
        <a-drawer
            v-model:open="openAdd"
            class="custom-class"
            root-class-name="root-class-name"
            :title="t('Pos.AddWorker')"
            placement="right"
            :width="modalWidth"
            @close="resetFormAdd"
        >

            <a-form ref="addFormRef" :model="addForm" :rules="rules" layout="vertical">

                <a-row :gutter="16">   
        
                    <a-col :span="24">
                        <a-form-item has-feedback name="username">
                            <template #label>
                            <span class="label-form">{{ t('Pos.Username') }}</span>
                            </template>
                            <a-input  v-model:value="addForm.username" :placeholder=" t('Pos.Username')" class="input-form" />
                        </a-form-item>
                    </a-col>   
                </a-row>

                <a-row :gutter="16">   
                    <a-col :span="12">
                        <a-form-item  has-feedback name="first_name">
                            <template #label>
                            <span class="label-form">{{ t('Pos.FisrtName') }}</span>
                            </template>
                            <a-input  v-model:value="addForm.first_name" :placeholder="t('Pos.FisrtName')" class="input-form" />
                        </a-form-item>
                    </a-col>

                    <a-col :span="12">
                        <a-form-item has-feedback name="last_name">
                            <template #label>
                            <span class="label-form">{{ t('Pos.LastName') }}</span>
                            </template>
                            <a-input  v-model:value="addForm.last_name" :placeholder="t('Pos.LastName')" class="input-form" />
                        </a-form-item>
                    </a-col>   
                </a-row>

                <a-row :gutter="16">
                    <a-col :span="12">
                
                        <a-form-item
                            name="email" has-feedback
                            :validate-status="validatingStatus"
                            :help="validatingHelp"
                        >

                            <template #label>
                            <span class="label-form">{{ t('Pos.Email') }}</span>
                            </template>

                            <a-input v-model:value="addForm.email" :placeholder="t('Pos.Email')" @blur="validateEmail" />
                        </a-form-item>

                    </a-col>
                    <a-col :span="12">
                        <a-form-item name="store_id">
                            <template #label>
                                <span class="label-form">{{ t('Pos.Store') }}</span>
                            </template>

                            <a-select  v-model:value="addForm.store_id">
                                <a-select-option v-for="store in state.stores" :key="store.id" :value="store.id" :selected="store.id === form.store_id ">{{ store.name }}</a-select-option>
                            </a-select>

                        </a-form-item>
                    </a-col>
                    
                </a-row>

                <!-- taraji -->

                <!-- <a-row :gutter="16">   
        
                    <a-col :span="24">
                        <a-form-item has-feedback name="discountValue">
                            <template #label>
                                <a-tooltip>
                                    <template #title>{{t('Pos.discountWorkerTxt')}}</template>
                                    <QuestionCircleOutlined class="tooltip-icon"/>
                                </a-tooltip>
                                <span class="label-form">{{ t('Pos.DiscountValue') }}</span>
                            </template>
                            <a-input  v-model:value="addForm.discountValue" :placeholder=" t('Pos.DiscountValue')" class="input-form" />
                        </a-form-item>
                    </a-col>   
                </a-row> -->

                
                <a-row :gutter="16">
                    <a-col :span="12">
                        <a-form-item  has-feedback name="pass">
                            <template #label>
                                <span class="label-form">{{ t('Pos.Password') }}</span>
                            </template>
                            <a-input-password v-model:value="addForm.pass" :placeholder="t('Pos.Password')" type="password" autocomplete="off" />

                        </a-form-item>

                    </a-col>

                    <a-col :span="12">
                        <a-form-item  has-feedback name="checkPass">
                            <template #label>
                                <span class="label-form">{{ t('Pos.Confirmpassword') }}</span>
                            </template>
                            <a-input-password v-model:value="addForm.checkPass" :placeholder="t('Pos.Confirmpassword')" type="password" autocomplete="off" />
                        </a-form-item>
                    </a-col>

                </a-row>

            </a-form>

            <template #footer>
            <a-space>
                <a-button  type="primary" @click="add">{{ t('Pos.Add') }}</a-button>
                <a-button type="primary" danger  @click="resetFormAdd">{{ t('Pos.Cancel') }}</a-button>
            </a-space>
            </template>


        </a-drawer>
        <!-- add drawer -->

        <!-- discount modal -->
        <a-modal v-model:open="openDiscountModal" >
            <template #footer>
                <div class="btns-container">
                    <a-button key="back" @click="cancelModal">{{ t('Pos.Cancel') }}</a-button>
                </div>
            </template>

            <template #title>
               <p>{{ t('Pos.ManageDiscounts') }}</p>
            </template>

            <a-form
                ref="discountFormRef"
                :model="discountForm"
                :rules="discountRules"
                layout="vertical"
            >

            
            <a-row :gutter="16">
                <a-col :span="24">
                <a-form-item name="value">
                    <template #label>
                    <span class="label-form">{{ t('Pos.DiscountValue') }}</span>
                    </template>
                    <a-input v-model:value="discountForm.value" :placeholder="t('Pos.DiscountValue')" class="input-form" />
                </a-form-item>
                </a-col>
            </a-row>
                
            </a-form>


        </a-modal>
        
       

    </div>


</template>
<script lang="ts">
import { defineComponent, ref, reactive, onMounted} from 'vue';
import type { Rule } from 'ant-design-vue/es/form';
import Swal from 'sweetalert2';
import {
EyeOutlined,
DeleteOutlined,
SearchOutlined,
EditOutlined,
CheckCircleTwoTone,
CloseCircleTwoTone,
PercentageOutlined,
QuestionCircleOutlined,

} from '@ant-design/icons-vue';
import { notification } from 'ant-design-vue';
import { storeService, userService} from '@/_services'
import dayjs , { Dayjs }from 'dayjs'; 
import relativeTime from 'dayjs/plugin/relativeTime';

import { useRouter } from 'vue-router';
import LbrxLoaderSpinner from '../../components/LbrxLoaderSpinner.vue';
import type { FormInstance } from 'ant-design-vue';
import { useI18n } from 'vue-i18n';
require('dayjs/locale/fr')
require('dayjs/locale/en')
require('dayjs/locale/ar')
export default defineComponent({

    name: 'indexWorkers',
    components: {
        EyeOutlined,
        DeleteOutlined,
        EditOutlined,
        LbrxLoaderSpinner,
        CheckCircleTwoTone,
        CloseCircleTwoTone,
        SearchOutlined,
        //PercentageOutlined,
        //QuestionCircleOutlined
    },
    setup() {
        const modalWidth = ref(720);
        const { t } = useI18n();
        dayjs.extend(relativeTime);
        const loading = ref(false);
        const router = useRouter();
        const navigatePage = (link: string) => {
            router.push(link); 
        };

        const state = reactive({
            searchText: '',
            searchedColumn: '',
            data: [] as any[],
            stores: [] as any[],
            totalPages:0,
            totalPagesSearch:0,
            beginSearch:false,

           
        });
        const searchInput = ref<HTMLInputElement | null>(null);

        const columns=[
           
            {
                title: t('Pos.Username'),
                key:'username',
                dataIndex: 'username',
                customFilterDropdown: true,
                onFilterDropdownOpenChange:  (visible: boolean) => {
                    if (visible  && !searchInput.value) {
                        setTimeout(() => {
                        const input = document.querySelector('.ant-table-filter-dropdown input');

                        if (input instanceof HTMLInputElement) {
                        searchInput.value = input;
                        searchInput.value.focus();
                        }
                        }, 100);
                    }
                }
            },
            {
                title:t('Pos.Email'),
                key:'email',
                dataIndex: 'email',
                customFilterDropdown: true,
                onFilterDropdownOpenChange:  (visible: boolean) => {
                    if (visible  && !searchInput.value) {
                        setTimeout(() => {
                        const input = document.querySelector('.ant-table-filter-dropdown input');

                        if (input instanceof HTMLInputElement) {
                        searchInput.value = input;
                        searchInput.value.focus();
                        }
                        }, 100);
                    }
                }
            },
            {
                title: t('Pos.MemberSince'),
                key:'created_at',
                dataIndex: 'created_at',

            },
            {
                title: t('Pos.Active'),
                key:'is_active',
                dataIndex: 'is_active',
            },
            {
                title: t('Pos.Actions'),
                key:'actions'
            }
        ];

        //form  
        const formRef =  ref<FormInstance>();
        const addFormRef = ref();

        const perPage = ref<number>(10);
        const currentPage = ref<number>(1);

        const form = reactive({
            id: '',
            name: '',
            email: '',
            first_name: '',
            last_name: '',
            email_verified_at:'',
            is_active:false,
            account_id:'',
            pin:'',
            badge_id:'',
            username: '',
            roles: '',
            created_at: null as Dayjs | null,
            updated_at: null as Dayjs | null,
            deleted_at: null as Dayjs | null,
            pass: '',
            checkPass: '',
            store_id:'',
            discountValue:'',

        });

        const addForm = reactive({
            id: '',
            name: '',
            email: '',
            first_name: '',
            last_name: '',
            email_verified_at:'',
            is_active:false,
            account_id:'',
            pin:'',
            badge_id:'',
            username: '',
            roles: '',
            created_at: null as Dayjs | null,
            updated_at: null as Dayjs | null,
            deleted_at: null as Dayjs | null,
            pass: '',
            checkPass: '',
            store_id:'',
            discountValue:'',
        })

        const validatePass = async (_rule: Rule, value: string) => {
            if (value === '') {
                return Promise.reject('Please input the password');
            } else {
                if (addForm.checkPass !== '' || form.checkPass !== '') {
                    formRef?.value?.validateFields('checkPass');
                }
                return Promise.resolve();
            }
        };
        const validatePass2 = async (_rule: Rule, value: string) => {
            if (value === '') {
                return Promise.reject('Please input the password again');
            } else if (value !== addForm.pass ||  value !== addForm.pass) {
                return Promise.reject("Password don't match");
            } else {
                return Promise.resolve();
            }
        };

        const rules: Record<string, Rule[]> = {
            name: [{ required: true, message: t('Pos.RequiredField') }],
            first_name: [{ required: true, message: t('Pos.RequiredField') }],
            last_name: [{ required: true, message: t('Pos.RequiredField') }],
            email: [
                { required: true, message: t('Pos.RequiredField') , trigger: 'change'},
                {
                    validator: (rule, value) => {
                        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
                        if (!emailRegex.test(value)) {
                            return Promise.reject(t('Pos.invalidEmail'));
                        }
                        return Promise.resolve();
                    }, trigger: 'change'
                }
                 
            ], 
            username: [{ required: true, message: t('Pos.RequiredField'), trigger: 'change'  }],
            pass: [{ required: true, validator: validatePass, trigger: 'change' }],
            checkPass: [{ validator: validatePass2, trigger: 'change' }],
            discountValue: [
                { required: true, message:  t('Pos.RequiredField') },
                { 
                validator: (_, value) => {
                    if (!isNaN(value)) {
                    return Promise.resolve();
                    }
                    return Promise.reject( t('Pos.numericField'));
                }
                }
            ],
        };

        const formatDisplayDate = (dateString: string) => {
            return dayjs(dateString).locale(localStorage.getItem('Language') || 'en').fromNow();
        }

        const formatStringDate=(str:string)=>{
            return dayjs(str).format("DD-MM-YYYY HH:mm:ss");
        }

        //notification
        const showSuccessNotification  = (message:string)=>{
            notification.success({
                message: message,
                duration: 3,
                top: '80px',
                style:{
                backgroundColor: '#bfffca8a',
                color: 'white'
                }
            });
        };

        //drawer
        const open = ref<boolean>(false);
        const openDisplay = ref<boolean>(false);
        const openAdd = ref<boolean>(false);

        const showAddDrawer = () => {
            updateModalWidth();
            openAdd.value = true;
        };

        const afterOpenChange = (bool: boolean) => {
            console.log('open', bool);
        };

        const showDisplayDrawer =(record:any)=>{
            updateModalWidth();
            openDisplay.value = true;
            getUserById(record.id);
        }

        const showDrawer = (record: any) => {
            updateModalWidth();
            open.value = true;
            getUserById(record.id);
        };


        const afterOpenAddChange = (bool: boolean) => {
            console.log('open', bool);
        };

        

        const resetFormAdd = () => {
            addFormRef.value.resetFields();
        };

        const cancelUpdate=()=>{
            open.value=false;
        }

        const getUsers=()=>{
            loading.value = true;
            userService.getWorkers(currentPage.value).then((response)=>{
                state.data=response.data;
                state.totalPages=response.meta.total;
                console.log('workerks', response.data);
            }).catch((error)=>{
                console.log("error get discounts", error);
            }).finally(()=>{
                loading.value = false;
            });
        }

        const getUserById=(id:string)=>{
            loading.value=true;
            userService.show(id)
            .then(res => {
                console.log('user', res.data);

                form.id = res.data.id;
                form.first_name=res.data.first_name;
                form.last_name=res.data.last_name;
                form.email=res.data.email;
                form.email_verified_at=res.data.email_verified_at;
                form.is_active=res.data.is_active;
                form.pin=res.data.pin;
                form.badge_id=res.data.badge_id;
                form.username=res.data.username;
                form.created_at=res.data.created_at;
                form.store_id=res.data.store_id;
                form.updated_at=res.data.updated_at;

                loading.value=false;
            })
            .catch(error => {
                loading.value=false;
                console.error('Error fetching user by id:', error);
            });

            
        }

        const updateUser =(id:string)=>{
        
            formRef!.value!
            .validate()
            .then(()=>{
                let payload = {
                    username:form.username,
                    first_name:form.first_name,
                    last_name:form.last_name,
                    pin:form.pin,
                    email:form.email,
                    is_active:form.is_active
                }
                console.log('payload', payload);
                loading.value = true;
                userService.update(id, payload).then((response)=>{
                   
                    state.data = state.data.map(el => el.id !== id ? el : response.data);
                    showSuccessNotification(t('Pos.UpdateWorkerSuccess'));
                    setTimeout(() => {
                        location.reload();

                    }, 500);
                }).catch((err) => {
                console.log(err)
                }).finally(() => {
                    loading.value = false;
                    open.value=false;

                }); 
            })
        }

        const deleteUser = (id: string) => {
            console.log(id);
            
            userService.delete(id).then((res) => {
                state.data = state.data.filter(e => e.id !== id);
                console.log('res', res);
            }).catch((err) => {
                console.log(err)
            }).finally(() => {
                showSuccessNotification(t('Pos.DeleteWorkerSuccess'));
                console.log('finish')
            })
        };

        const showDeletePopup = (record: any) => {
            Swal.fire({
                title: t('Pos.TitleDeleteWorker'),
                text: t('Pos.TextDeleteWorker'),
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: "#fc8019",
                confirmButtonText: t('Pos.Delete'),
                cancelButtonText: t('Pos.Cancel'),
                cancelButtonColor: "#d33",
                customClass: {
                    popup: 'swal2-popup'
                }

            }).then((result) => {
                if (result.isConfirmed) {
                    deleteUser(record.id);
                } else if (result.dismiss === Swal.DismissReason.cancel) {
                    console.log("cancel delete ");
                }
            })
        };

        const validatingStatus = ref('');
        const validatingHelp = ref('');

        const validateEmail = () => {

            if(addForm.email===''){
                return;
            }

            console.log('begin validation email');
            const payload = { email: addForm.email };
            validatingStatus.value = 'validating'; 
            validatingHelp.value = 'The email is being validated...';

            userService.verifyEmail(payload)
            .then(response => {
                console.log('response', response);
                if (addForm.email.length===0) {
                    validatingStatus.value = 'error'; 
                    validatingHelp.value = 'The email is already in use.';
                }
                else if (response.message==="Email available for use") {
                    validatingStatus.value = 'success';
                    validatingHelp.value = 'The email is valid.';
                } else {
                    validatingStatus.value = 'error'; 
                    validatingHelp.value = 'The email is already in use.';
                }
            })
            .catch(error => {
            console.error('Error validating email:', error);
            validatingStatus.value = 'error'; // Set error status if API call fails
            validatingHelp.value = 'Error validating email.';
            });
        };
       

        const add=()=>{
           
            addFormRef.value
                .validate()
                .then(()=>{
                   
                    let store_id = addForm.store_id || localStorage.getItem('store') ? JSON.parse(localStorage.getItem('store')!).id : '';

                    let payload = {
                        name:addForm.name,
                        email:addForm.email,
                        first_name:addForm.first_name,
                        last_name:addForm.last_name,
                        username:addForm.username,
                        password:addForm.pass,
                        //pin: addForm.pin,
                        role_id:2,
                        store_id:store_id
                    }

                    console.log('payload', payload);
                    loading.value=true;
                    userService.create(payload).then((rep)=>{
                        
                        state.data.push(rep.data);
                        resetFormAdd();
                        showSuccessNotification(t('Pos.AddWorkerSuccess'));
                        setTimeout(() => {
                        location.reload();

                    }, 500);
                    }).catch((error) => {
                        console.error('Error adding Worker:', error); 
                    }).finally(()=>{
                        loading.value=false;
                        openAdd.value=false;

                    });
           
                }).catch((error:any) => {
                    console.error('Validation error:', error);
                });
                

        }

       

        const getStores=()=>{
            storeService.getUserStores().then((response)=>{
                state.stores=response.data;
            }).catch((error)=>{
                console.log('error get stores api', error);
            });
        }


        const formatDate=(dateString:string)=>{
            return dayjs(dateString);
        }

        const handlePageChange = (newPage:any) => {
            currentPage.value = newPage;
            loading.value = true;

            console.log('per page', perPage.value);
            console.log('page', currentPage.value);
            

            userService.getWorkers(currentPage.value).then((response)=>{
            
                console.log('data', response.data)
                state.data=response.data;
            }).catch((error)=>{
                console.log("error api : get workers", error);
            })
            .finally(()=>{
                loading.value = false;
            });
        }

        //search
        const filter=(key:string, value:string)=>{
            currentPage.value=1;
            userService.filterWorkers(key,value, currentPage.value.toString()).then((res)=>{
                state.data=res.data;
                state.totalPagesSearch= res.meta.total;
                state.beginSearch=true;
            }).catch((error)=>{
                console.log('error fetching', error);
            });
        }

        //search
        const handleSearch = (selectedKeys:any, confirm:any, dataIndex:any) => {
            console.log('selected keys', selectedKeys);
            confirm();
            state.searchText = selectedKeys[0];
            state.searchedColumn = dataIndex;

            if (dataIndex === 'email' && state.searchText!=='') {
               
                filter("email",state.searchText );
            }

            if (dataIndex === 'username'  && state.searchText!=='') {
                
                
                filter("username",state.searchText );
            }
        };

        const handleReset =  (clearFilter: (arg: any) => void)=> {
            console.log('reset');

            clearFilter({ confirm: true });
            state.searchText = '';
            state.totalPagesSearch=0;
            state.beginSearch=false;
            getUsers();
        };


        const updateModalWidth = () => {
            modalWidth.value = window.innerWidth <=1200 ? window.innerWidth : 720;
        };

        // discount drawer 
        const openDiscountModal = ref<boolean>(false);
        const discountFormRef = ref();
        const discountForm = reactive({
            value:'',
            WorkerId:'',
        })

        const discountRules: Record<string, Rule[]> = {
            value: [
                { required: true, message:  t('Pos.RequiredField') },
                { 
                validator: (_, value) => {
                    if (!isNaN(value)) {
                    return Promise.resolve();
                    }
                    return Promise.reject( t('Pos.numericField'));
                }
                }
            ]
        }


        const openModal=(id:string)=>{
            discountForm.WorkerId=id;
            openDiscountModal.value=true;
        }

        const cancelModal = () => {
            openDiscountModal.value = false;
            discountFormRef.value.resetFields();
        };
        const discountValue = ref<number>(30);



        onMounted(() => {
            getUsers();
            getStores();
            searchInput.value = document.querySelector('.ant-table-filter-dropdown input');

        });



        return{
            discountValue,
            updateModalWidth,
            modalWidth,
            t,
            loading,
            navigatePage,
            state,
            columns,
            formRef,
            addFormRef,
            perPage,
            currentPage,
            form,
            addForm,
            rules,
            formatDisplayDate,
            open,
            openDisplay,
            openAdd,
            showAddDrawer,
            afterOpenChange,
            showDisplayDrawer,
            showDrawer,
            afterOpenAddChange,
            resetFormAdd,
            showDeletePopup,
            updateUser,
            formatDate,
            handlePageChange,
            add,
            validatingStatus,
            validatingHelp,
            validateEmail,
            searchInput,
            handleSearch,
            handleReset,
            cancelUpdate,
            formatStringDate,
            openDiscountModal,
            openModal,
            cancelModal,
            discountForm,
            discountFormRef,
            discountRules,



            



        }

        
    },
})
</script>

<style scoped>

    .add-btn {
        color: #fc8019;
        border: 2px solid #fc8019;
        box-shadow: inset 0 0 0 0 #fc8019;
    }

    .add-btn:hover {
        color: #fff;
        box-shadow: inset 0 -100px 0 0 #fc8019;
        border-color: #fc8019;
    }

    .btn-container {
        display: flex;
        margin-bottom: 20px;
        justify-content: flex-end;
    }

    .label-form {
        font-family: "Exo 2", sans-serif;
        font-size: 14px;
        font-weight: 600;
    }

    .date-picker {
        width: 100%
    }
    .range-picker{
        width: 100% 
    }

    .switch-container {
        display: flex;
        align-items: center;
    }
    .switch-container p {
        margin-right: 15px ;
    }

    .view-btn{
        color: #fc8019;
        border-color: #fc8019;
        background: #fafafa;
    }
   
    .breadcrumb-icon{
        cursor: pointer;
    }

    .pagination{
        margin-top: 10px;
        display: flex;
        justify-content: flex-end;
    }

    .loader-container {
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .info-title{
        font-size: 15px !important;
        font-weight: bold;
        margin-right: 4px;
    }
    .paginationArrows{
    direction: ltr;
    }
    .table-container {
    overflow-x: auto;
    }

    .variant-btn{
    color: #3b8dffdf;
    border-color: #3b8dffdf;
    background: #fafafa;
    }

  .variant-btn:hover{
    color: #3b8dffdf;
    border-color: #3b8dffdf;
    background: #fafafa;
  }

    .tooltip-icon{
        margin-right: 5px;
    }

   .discount-value-container{
        display: flex;
        align-items: baseline;
   }

   .discount-amount-txt{
        font-weight: 500;
        font-size: 16px;
   }


</style>